import {Asset, Chain} from "../transactionserver/types";

export const getChainName = (chainId: number, chains: Chain[]): string => {
  const chain = chains.find(c => c.id === chainId);
  return chain?.name || 'Unknown Chain';
};

export const getAssetDetails = (assetId: number, assets: Asset[]) => {
  const asset = assets.find(a => a.id === assetId);
  return {
    name: asset?.name || 'Unknown Asset',
    symbol: asset?.asset || 'Unknown'
  };
};

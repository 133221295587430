import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import {
  Paper,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Grid,
  Box,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

interface OrderBookEntry {
  price: number;
  amount: number;
}

interface OrderBook {
  bids: OrderBookEntry[];
  asks: OrderBookEntry[];
  timestamp: number;
}

interface PairInfo {
  symbol: string;
  baseAsset: string;
  quoteAsset: string;
}

interface FinerySpreadsProps {
  socket: Socket | null;
}

const FinerySpreads: React.FC<FinerySpreadsProps> = ({ socket }) => {
  const [selectedPair, setSelectedPair] = useState<PairInfo | null>(null);
  const [pairs, setPairs] = useState<PairInfo[]>([]);
  const [orderBook, setOrderBook] = useState<OrderBook | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch available pairs on component mount
  useEffect(() => {
    if (!socket) return;

    socket.emit('getFineryPairs');
    socket.on('fineryPairs', (data: PairInfo[]) => {
      // Sort pairs alphabetically
      const sortedPairs = [...data].sort((a, b) =>
        a.symbol.localeCompare(b.symbol)
      );
      setPairs(sortedPairs);
      if (sortedPairs.length > 0 && !selectedPair) {
        setSelectedPair(sortedPairs[0]);
      }
    });

    return () => {
      socket.off('fineryPairs');
    };
  }, [socket]);

  // Handle order book subscription
  useEffect(() => {
    if (!socket || !selectedPair) return;

    // Set loading state when changing pairs
    setIsLoading(true);
    setOrderBook(null);

    // Unsubscribe from previous pair
    socket.emit('unsubscribeOrderBook', {
      symbol: selectedPair.symbol
    });

    // Subscribe to new pair
    socket.emit('subscribeOrderBook', {
      symbol: selectedPair.symbol
    });

    // Set up listeners
    const handleOrderBookUpdate = (data: OrderBook) => {
      setOrderBook(data);
      setError(null);
      setIsLoading(false);
    };

    const handleError = (errorMsg: string) => {
      setError(errorMsg);
      setIsLoading(false);
    };

    socket.on('orderBookUpdate', handleOrderBookUpdate);
    socket.on('fineryError', handleError);

    // Cleanup
    return () => {
      socket.off('orderBookUpdate', handleOrderBookUpdate);
      socket.off('fineryError', handleError);
      // Unsubscribe on cleanup if component unmounts
      socket.emit('unsubscribeOrderBook', {
        symbol: selectedPair.symbol
      });
    };
  }, [socket, selectedPair]);

  const calculateSpread = (bestBid: number, bestAsk: number): number => {
    if (!bestBid || !bestAsk) return 0;
    return ((bestAsk - bestBid) / bestBid) * 10000;
  };

  const bestBid = orderBook?.bids[0]?.price || 0;
  const bestAsk = orderBook?.asks[0]?.price || 0;
  const spread = calculateSpread(bestBid, bestAsk);

  if (!pairs.length) {
    return (
      <Paper sx={{ p: 3 }}>
        <Alert severity="info">Loading available pairs...</Alert>
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h6">
          Finery Order Book
        </Typography>
        <FormControl sx={{ width: 300 }}>
          <Autocomplete
            value={selectedPair}
            onChange={(_, newValue) => {
              setSelectedPair(newValue);
            }}
            options={pairs}
            getOptionLabel={(option) => option.symbol}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Trading Pair"
                size="small"
              />
            )}
            isOptionEqualToValue={(option, value) =>
              option.symbol === value.symbol
            }
            renderOption={(props, option) => (
              <li {...props}>
                <Typography>
                  {option.symbol}
                  <Typography
                    component="span"
                    color="textSecondary"
                    sx={{ ml: 1, fontSize: '0.875rem' }}
                  >
                    ({option.baseAsset}/{option.quoteAsset})
                  </Typography>
                </Typography>
              </li>
            )}
          />
        </FormControl>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {isLoading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : orderBook && (
        <>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="body2" color="textSecondary">
              Last Updated: {new Date(orderBook.timestamp).toLocaleString()}
            </Typography>
            <Typography variant="subtitle1">
              Spread: <span style={{ color: '#2196f3' }}>{spread.toFixed(2)} bps</span>
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ mb: 1, color: 'success.main' }}>
                Bids
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Price</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderBook.bids.map((bid, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ color: 'success.main', fontFamily: 'monospace' }}>
                          {bid.price}
                        </TableCell>
                        <TableCell align="right" sx={{ fontFamily: 'monospace' }}>
                          {bid.amount}
                        </TableCell>
                        <TableCell align="right" sx={{ fontFamily: 'monospace' }}>
                          {(bid.price * bid.amount)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ mb: 1, color: 'error.main' }}>
                Asks
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Price</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderBook.asks.map((ask, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ color: 'error.main', fontFamily: 'monospace' }}>
                          {ask.price}
                        </TableCell>
                        <TableCell align="right" sx={{ fontFamily: 'monospace' }}>
                          {ask.amount}
                        </TableCell>
                        <TableCell align="right" sx={{ fontFamily: 'monospace' }}>
                          {(ask.price * ask.amount)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default FinerySpreads;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Alert,
  CircularProgress,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SelectChangeEvent,
  ClickAwayListener,
  TextField,
  IconButton,
  Tooltip,
  Switch
} from '@mui/material';
import { Socket } from 'socket.io-client';
import { Edit as EditIcon, Add as AddIcon } from '@mui/icons-material';
import EditWalletDialog from './EditWalletDialog';
import { AddressResponse, Asset, Chain, WalletAddress, WalletDetail } from "./types";
import { getAssetDetails, getChainName } from "../util/TransactionServerHelpers";

interface EditableDescriptionProps {
  addressId: string;
  initialDescription: string;
  onSave: (addressId: string, newDescription: string) => Promise<void>;
}

interface AddressUpdateResponse {
  status: 'success' | 'error';
  message: string;
}

const EditableDescription: React.FC<EditableDescriptionProps> = ({
  addressId,
  initialDescription,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(initialDescription);
  const [error, setError] = useState<string | null>(null);

  const handleSave = async () => {
    try {
      await onSave(addressId, description);
      setIsEditing(false);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save description');
    }
  };

  if (isEditing) {
    return (
      <ClickAwayListener onClickAway={() => setIsEditing(false)}>
        <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSave();
            }
          }}
          error={!!error}
          helperText={error}
          size="small"
          autoFocus
          fullWidth
        />
      </ClickAwayListener>
    );
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Chip label={description} />
      <Tooltip title="Edit description">
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(true);
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

interface WalletDetailsProps {
  socket: Socket | null;
}

interface NewAddressData {
  chain_id: number | '';
  account_type: 'OA Internal' | 'OA Users' | '';
}

const WalletDetails: React.FC<WalletDetailsProps> = ({ socket }) => {
  const { walletId } = useParams<{ walletId: string }>();
  const [walletDetails, setWalletDetails] = useState<WalletDetail | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openCreateAddressDialog, setOpenCreateAddressDialog] = useState(false);

  // Server configuration state
  const [chains, setChains] = useState<Chain[]>([]);
  const [assets, setAssets] = useState<Asset[]>([]);

  // Address pagination and filtering state
  const [addresses, setAddresses] = useState<WalletAddress[]>([]);
  const [totalAddresses, setTotalAddresses] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [chainFilter, setChainFilter] = useState<number | ''>('');
  const [accountTypeFilter, setAccountTypeFilter] = useState<string>('');
  const [newAddressData, setNewAddressData] = useState<NewAddressData>({
    chain_id: '',
    account_type: ''
  });

  // Fetch server configuration
  useEffect(() => {
    if (socket) {
      socket.emit('getTransactionServerConfig');
      socket.on('transactionServerConfig', (config: { assets: Asset[], chains: Chain[] }) => {
              console.log('Config received:', {
        chains: config.chains,
        assets: config.assets
      });
        setAssets(config.assets);
        setChains(config.chains);
      });

      return () => {
        socket.off('transactionServerConfig');
      };
    }
  }, [socket]);

  const fetchWalletDetails = () => {
    if (socket && walletId) {
      setLoading(true);
      setError(null);
      socket.emit('getWalletDetails', walletId);
    }
  };

  const fetchAddresses = () => {
    if (socket && walletId) {
      socket.emit('getWalletAddresses', {
        wallet_id: walletId,
        page: page + 1,
        rowsPerPage,
        chain_id: chainFilter || undefined,
        accountType: accountTypeFilter || undefined
      });
    }
  };
 const handleToggleBlacklist = async (addressId: string, currentBlacklistStatus: boolean) => {
    if (!socket) return;
    console.log('Toggling blacklist:', {addressId, currentStatus: currentBlacklistStatus});
    try {
      const response = await new Promise<{ status: string; message?: string }>((resolve) => {
        socket.emit('updateAddress', {
          address_id: addressId,
          blacklisted: !currentBlacklistStatus
        }, (res: any) => resolve(res));
      });
      console.log('Update response:', response);
      if (response.status === 'error') {
        setError(response.message || 'Failed to update blacklist status');
        return;
      }

      fetchAddresses();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update blacklist status');
    }
  };


  useEffect(() => {
    if (socket && walletId) {
      fetchWalletDetails();
      fetchAddresses();

      socket.on('walletDetails', (data: WalletDetail) => {
        setWalletDetails(data);
        setLoading(false);
      });

      socket.on('walletAddressesData', (data: AddressResponse) => {
        setAddresses(data.addresses);
        setTotalAddresses(data.total);
      });

      socket.on('error', (error: string) => {
        setError(error);
        setLoading(false);
      });

      return () => {
        socket.off('walletDetails');
        socket.off('walletAddressesData');
        socket.off('error');
      };
    }
  }, [socket, walletId, page, rowsPerPage, chainFilter, accountTypeFilter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChainFilterChange = (event: SelectChangeEvent<number | ''>) => {
    const value = event.target.value;
    setChainFilter(value === '' ? '' : Number(value));
    setPage(0);
  };

  const handleAccountTypeFilterChange = (event: SelectChangeEvent<string>) => {
    setAccountTypeFilter(event.target.value);
    setPage(0);
  };

  const handleCreateAddress = () => {
    if (socket && walletId && newAddressData.chain_id && newAddressData.account_type) {
      socket.emit('createWalletAddress', {
        wallet_id: walletId,
        ...newAddressData
      });
      setOpenCreateAddressDialog(false);
      fetchAddresses();
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!walletDetails) {
    return (
      <Alert severity="info" sx={{ mt: 2 }}>
        No wallet details found for ID: {walletId}
      </Alert>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">
          Wallet: {walletDetails.metadata.wallet_name}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={() => setOpenEditDialog(true)}
        >
          Edit Details
        </Button>
      </Box>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography>Wallet ID: {walletDetails.wallet_id}</Typography>
          <Typography>Description: {walletDetails.metadata.wallet_description}</Typography>
          <Typography>Entity: {walletDetails.metadata.wallet_entity}</Typography>
          <Typography>Created: {new Date(walletDetails.created_at).toLocaleString()}</Typography>
        </CardContent>
      </Card>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Addresses</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenCreateAddressDialog(true)}
        >
          Create New Address
        </Button>
      </Box>

      <Box display="flex" gap={2} mb={2}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Chain</InputLabel>
          <Select
            value={chainFilter}
            onChange={handleChainFilterChange}
            label="Chain"
          >
            <MenuItem value="">All</MenuItem>
            {chains.map(chain => (
              <MenuItem key={chain.id} value={chain.id}>
                {chain.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Account Type</InputLabel>
          <Select
            value={accountTypeFilter}
            onChange={handleAccountTypeFilterChange}
            label="Account Type"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="OA Internal">OA Internal</MenuItem>
            <MenuItem value="OA Users">OA Users</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {addresses.length === 0 ? (
        <Alert severity="info" sx={{ mt: 2 }}>
          No addresses found matching the current filters
        </Alert>
      ) : (
        <>
                <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>Asset</TableCell>
              <TableCell>Chain</TableCell>
              <TableCell>Account Type</TableCell>
              <TableCell>Classification</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Blacklisted</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addresses.map((address) => (
              <TableRow key={address.id}>
                <TableCell>
                  <Typography
                    sx={{
                      fontFamily: 'monospace',
                      wordBreak: 'break-all'
                    }}
                  >
                    {address.address}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    label={getAssetDetails(address.asset, assets).symbol}
                    title={getAssetDetails(address.asset, assets).name}
                  />
                </TableCell>
                <TableCell>
                  <Chip label={getChainName(address.chain, chains)} />
                </TableCell>
                <TableCell>
                  <Chip
                    label={address.account_type}
                    color={address.account_type === 'OA Internal' ? 'primary' : 'secondary'}
                  />
                </TableCell>
                <TableCell>
                  <Chip label={address.classification} />
                </TableCell>
                <TableCell>
                  <EditableDescription
                    addressId={address.id}
                    initialDescription={address.description}
                    onSave={async (addressId, newDescription) => {
                      if (socket) {
                        const response = await new Promise<AddressUpdateResponse>((resolve) => {
                          socket.emit('updateAddress', {
                            address_id: addressId,
                            description: newDescription,
                          }, resolve);
                        });
                        if (response.status === 'error') {
                          throw new Error(response.message);
                        }
                        fetchAddresses();
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Switch
                      checked={!!address.blacklisted}
                      onChange={() => handleToggleBlacklist(address.id, !!address.blacklisted)}
                      color="error"
                    />
                    <Chip
                      label={address.blacklisted ? 'Yes' : 'No'}
                      color={address.blacklisted ? 'error' : 'success'}
                      size="small"
                    />
                  </Box>
                </TableCell>
                <TableCell>{new Date(address.created_at).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

          <TablePagination
            component="div"
            count={totalAddresses}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </>
      )}

      <Dialog open={openCreateAddressDialog} onClose={() => setOpenCreateAddressDialog(false)}>
        <DialogTitle>Create New Address</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Chain</InputLabel>
            <Select
              value={newAddressData.chain_id}
              onChange={(e) => setNewAddressData({ ...newAddressData, chain_id: Number(e.target.value) || '' })}
              label="Chain"
            >
              {chains.map(chain => (
                <MenuItem key={chain.id} value={chain.id}>
                  {chain.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Account Type</InputLabel>
            <Select
              value={newAddressData.account_type}
              onChange={(e) => setNewAddressData({
                ...newAddressData,
                account_type: e.target.value as 'OA Internal' | 'OA Users' | ''
              })}
              label="Account Type"
            >
              <MenuItem value="OA Internal">OA Internal</MenuItem>
              <MenuItem value="OA Users">OA Users</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateAddressDialog(false)}>Cancel</Button>
          <Button
            onClick={handleCreateAddress}
            variant="contained"
            disabled={!newAddressData.chain_id || !newAddressData.account_type}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {walletDetails && (
        <EditWalletDialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          socket={socket}
          wallet={walletDetails}
          onUpdate={fetchWalletDetails}
        />
      )}
    </Paper>
  );
};

export default WalletDetails;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TablePagination, TextField, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { Socket } from 'socket.io-client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface Organization {
  id: string;
  name: string;
  description: string;
  onboarded_entity: 'ALPHA NODE S.P.L' | 'ALPHA NODE SP. Z O.O.';
  kyc_submitted: boolean;
  account_active: boolean;
  fee_tier: string;
  date_created: string;
}

interface OrganizationsPageProps {
  socket: Socket | null;
}

interface BooleanIndicatorProps {
  value: boolean;
}

const BooleanIndicator: React.FC<BooleanIndicatorProps> = ({ value }) => {
  return value ? (
    <CheckCircleIcon style={{ color: 'green' }} />
  ) : (
    <CancelIcon style={{ color: 'red' }} />
  );
};


const OrganizationsPage: React.FC<OrganizationsPageProps> = ({ socket }) => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [onboardedEntityFilter, setOnboardedEntityFilter] = useState<string | null>(null);
  const [accountActiveFilter, setAccountActiveFilter] = useState<boolean | null>(null);
  const [kycSubmittedFilter, setKycSubmittedFilter] = useState<boolean | null>(null);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };
  useEffect(() => {
    if (socket) {
      fetchOrganizations();

      socket.on('organizationsData', (data) => {
        setOrganizations(data.organizations);
        setTotalCount(data.total);
      });
    }

    return () => {
      if (socket) {
        socket.off('organizationsData');
      }
    };
  }, [socket, page, rowsPerPage, searchTerm, onboardedEntityFilter, accountActiveFilter, kycSubmittedFilter]);

  const fetchOrganizations = () => {
    socket?.emit('requestOrganizations', {
      page: page + 1,
      rowsPerPage,
      searchTerm,
      onboardedEntity: onboardedEntityFilter || undefined,
      accountActive: accountActiveFilter,
      kycSubmitted: kycSubmittedFilter
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Organizations
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <FormControl sx={{ minWidth: 120, mr: 2 }}>
        <InputLabel>Onboarded Entity</InputLabel>
        <Select
          value={onboardedEntityFilter || ''}
          onChange={(e) => setOnboardedEntityFilter(e.target.value as string | null)}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="ALPHA NODE S.P.L">ALPHA NODE S.P.L</MenuItem>
          <MenuItem value="ALPHA NODE SP. Z O.O.">ALPHA NODE SP. Z O.O.</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 120, mr: 2 }}>
        <InputLabel>Account Active</InputLabel>
        <Select
          value={accountActiveFilter === null ? '' : accountActiveFilter.toString()}
          onChange={(e) => setAccountActiveFilter(e.target.value === '' ? null : e.target.value === 'true')}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="true">Active</MenuItem>
          <MenuItem value="false">Inactive</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 120, mr: 2 }}>
        <InputLabel>KYC Submitted</InputLabel>
        <Select
          value={kycSubmittedFilter === null ? '' : kycSubmittedFilter.toString()}
          onChange={(e) => setKycSubmittedFilter(e.target.value === '' ? null : e.target.value === 'true')}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="true">Submitted</MenuItem>
          <MenuItem value="false">Not Submitted</MenuItem>
        </Select>
      </FormControl>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date Created</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Org ID</TableCell>
              <TableCell>Onboarded Entity</TableCell>
              <TableCell>KYC Submitted</TableCell>
              <TableCell>Account Active</TableCell>
              <TableCell>Fee Tier</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((org) => (
              <TableRow key={org.id} component={Link} to={`/organization/${org.id}`} style={{ textDecoration: 'none' }}>
                <TableCell>{formatDate(org.date_created)}</TableCell>
                <TableCell>{org.name}</TableCell>
                <TableCell>{org.description}</TableCell>
                <TableCell>{org.id}</TableCell>
                <TableCell>{org.onboarded_entity}</TableCell>
                <TableCell align="center">
                  <BooleanIndicator value={org.kyc_submitted} />
                </TableCell>
                <TableCell align="center">
                  <BooleanIndicator value={org.account_active} />
                </TableCell>
                <TableCell>{org.fee_tier}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default OrganizationsPage;

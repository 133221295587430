import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import {
  Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  Select, MenuItem, FormControl, InputLabel, Box, Tabs, Tab, Alert,
  RadioGroup, FormControlLabel, Radio, Chip, FormHelperText
} from '@mui/material';
import { Asset, Chain, Wallet } from "../transactionserver/types";

interface TransactionRequestsProps {
  socket: Socket | null;
}

type TransactionType = 'credit_user' | 'debit_user' | 'from_exchange' | 'to_exchange' | 'send_to_address' | 'exchange_to_exchange';

interface Exchange {
  id: string;
  name: string;
  entity: string;
}

interface TransactionRequest {
  id: string;
  type: TransactionType;
  userId?: string;
  from_exchange?: string;
  to_exchange?: string;
  amount: number;
  asset: string;
  chain?: string;
  status: 'pending' | 'approved' | 'rejected';
  createdBy: string;
  approvedBy?: string;
  createdAt: string;
  updatedAt: string;
  to_wallet?: string;
  from_wallet?: string;
  to_address?: string;
  description?: string;
  settlement_details?: string;
}

interface ApprovalQueueItem {
  id: string;
  type: 'deposit' | 'withdrawal' | 'add_withdrawal_address' | 'add_deposit_address';
  kytRiskScore: number;
  status: 'pending' | 'approved' | 'rejected';
  details: string;
  kytUrl: string;
}

interface HistoricalApprovedEvent extends ApprovalQueueItem {
  approvedBy: string;
  approvedAt: string;
}

interface NewTransactionRequest {
  type: TransactionType;
  userId?: string;
  from_exchange?: string;
  to_exchange?: string;
  amount: string;
  asset: string;
  chain?: string;
  to_wallet?: string;
  from_wallet?: string;
  to_address?: string;
  description?: string;
  settlement_details?: string;
}

const initialRequest: NewTransactionRequest = {
  type: 'credit_user',
  amount: '0',
  asset: '',
};

const TransactionRequests: React.FC<TransactionRequestsProps> = ({ socket }) => {
  const [tabValue, setTabValue] = useState(0);
  const [manualRequests, setTransactionRequests] = useState<TransactionRequest[]>([]);
  const [approvalQueue, setApprovalQueue] = useState<ApprovalQueueItem[]>([]);
  const [historicalEvents, setHistoricalEvents] = useState<HistoricalApprovedEvent[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newRequest, setNewRequest] = useState<NewTransactionRequest>(initialRequest);
  const [selectedItem, setSelectedItem] = useState<ApprovalQueueItem | null>(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [chains, setChains] = useState<Chain[]>([]);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [exchanges, setExchanges] = useState<Exchange[]>([]);
  const [selectedChain, setSelectedChain] = useState<number | ''>('');
  const [assetType, setAssetType] = useState<'crypto' | 'fiat'>('crypto');

  useEffect(() => {
    if (socket) {
      socket.emit('getTransactionServerConfig');
      socket.emit('getExchanges');
      socket.on('transactionServerConfig', (config: { assets: Asset[], chains: Chain[] }) => {
        setAssets(config.assets);
        setChains(config.chains);
      });
      socket.on('exchangesData', (data: Exchange[]) => {
        setExchanges(data);
      });
      socket.emit('getWallets');
      socket.on('walletsData', (data: Wallet[]) => {
        setWallets(data);
      });

      fetchTransactionData();

      return () => {
        socket.off('transactionServerConfig');
        socket.off('exchangesData');
        socket.off('walletsData');
        socket.off('transactionRequests');
        socket.off('approvalQueue');
        socket.off('historicalApprovedEvents');
      };
    }
  }, [socket]);

  const fetchTransactionData = () => {
    if (!socket) return;
    socket.emit('getTransactionRequests');
    socket.emit('getApprovalQueue');
    socket.emit('getHistoricalApprovedEvents');

    socket.on('transactionRequests', (data: TransactionRequest[]) => {
      setTransactionRequests(data);
    });

    socket.on('approvalQueue', (data: ApprovalQueueItem[]) => {
      setApprovalQueue(data);
    });

    socket.on('historicalApprovedEvents', (data: HistoricalApprovedEvent[]) => {
      setHistoricalEvents(data);
    });
  };

  // Filter assets based on selected chain and asset type
const filteredAssets = React.useMemo(() => {
  if (assetType === 'fiat') {
    return assets.filter(asset => !asset.chain);
  }
  if (!selectedChain) return [];

  const selectedChainName = chains.find(c => c.id === selectedChain)?.name;
  return assets.filter(asset => asset.chain === selectedChainName);
}, [selectedChain, assetType, assets, chains]);

  const handleChainChange = (chainId: number | '') => {
    setSelectedChain(chainId);
    setNewRequest(prev => ({
      ...prev,
      chain: chainId ? String(chainId) : '',
      asset: ''
    }));
  };

  const handleCreateRequest = () => {
    if (!socket) return;

    if (!validateRequest()) {
      return;
    }

    socket.emit('createTransactionRequest', newRequest);
    handleCloseDialog();
  };
const validateRequest = (): boolean => {
    const errors: string[] = [];
    const numAmount = parseFloat(newRequest.amount);
    if (isNaN(numAmount) || numAmount <= 0) {
      errors.push("Amount must be greater than 0");
    }

    if (!newRequest.asset) {
      errors.push("Asset must be selected");
    }

    switch (newRequest.type) {
      case 'credit_user':
      case 'debit_user':
        if (!newRequest.userId) {
          errors.push("User ID is required");
        }
        break;

      case 'from_exchange':
        if (!newRequest.from_exchange) {
          errors.push("Source exchange is required");
        }
        if (!newRequest.to_wallet) {
          errors.push("Destination wallet is required");
        }
        break;

      case 'to_exchange':
        if (!newRequest.to_exchange) {
          errors.push("Destination exchange is required");
        }
        if (!newRequest.from_wallet) {
          errors.push("Source wallet is required");
        }
        break;

      case 'send_to_address':
        if (!newRequest.from_wallet) {
          errors.push("Source wallet is required");
        }
        if (!newRequest.to_address) {
          errors.push("Destination address is required");
        }
        break;

      case 'exchange_to_exchange':
        if (!newRequest.from_exchange) {
          errors.push("Source exchange is required");
        }
        if (!newRequest.to_exchange) {
          errors.push("Destination exchange is required");
        }
        if (newRequest.from_exchange === newRequest.to_exchange) {
          errors.push("Source and destination exchanges must be different");
        }
        break;
    }

    if (assetType === 'crypto' && !newRequest.chain) {
      errors.push("Chain must be selected for crypto assets");
    }

    if (errors.length > 0) {
      setError(errors.join(". "));
      return false;
    }

    return true;
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewRequest(initialRequest);
    setError(null);
    setAssetType('crypto');
    setSelectedChain('');
  };

  const handleApprove = (id: string) => {
    if (socket) {
      socket.emit('approveTransactionRequest', id);
    }
  };

  const handleReject = (id: string) => {
    if (socket) {
      socket.emit('rejectTransactionRequest', id);
    }
  };

  const renderAssetTypeSelection = () => (
    <FormControl component="fieldset" sx={{ mb: 2 }}>
      <RadioGroup
        row
        value={assetType}
        onChange={(e) => {
          setAssetType(e.target.value as 'crypto' | 'fiat');
          setSelectedChain('');
          setNewRequest(prev => ({ ...prev, asset: '', chain: undefined }));
        }}
      >
        <FormControlLabel value="crypto" control={<Radio />} label="Crypto" />
        <FormControlLabel value="fiat" control={<Radio />} label="Fiat" />
      </RadioGroup>
    </FormControl>
  );

  const renderWalletSelect = (label: string, value: string | undefined, field: 'from_wallet' | 'to_wallet') => (
    <FormControl fullWidth margin="normal">
      <InputLabel>{label}</InputLabel>
      <Select
        value={value || ''}
        onChange={(e) => setNewRequest(prev => ({ ...prev, [field]: e.target.value }))}
      >
        {wallets.map((wallet) => (
          <MenuItem key={wallet.wallet_id} value={wallet.wallet_id}>
            {wallet.metadata.wallet_name} ({wallet.metadata.wallet_entity})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderExchangeSelect = (label: string, value: string | undefined, field: 'from_exchange' | 'to_exchange') => (
    <FormControl fullWidth margin="normal">
      <InputLabel>{label}</InputLabel>
      <Select
        value={value || ''}
        onChange={(e) => setNewRequest(prev => ({ ...prev, [field]: e.target.value }))}
      >
        {exchanges.map((exchange) => (
          <MenuItem key={exchange.id} value={exchange.id}>
            {exchange.name}: {exchange.entity}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderCommonFields = () => (
    <>
      {assetType === 'crypto' && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Chain</InputLabel>
          <Select
            value={selectedChain}
            onChange={(e) => handleChainChange(e.target.value as number | '')}
          >
            <MenuItem value="">Select Chain</MenuItem>
            {chains.map((chain) => (
              <MenuItem key={chain.id} value={chain.id}>
                {chain.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth margin="normal">
        <InputLabel>Asset</InputLabel>
        <Select
          value={newRequest.asset}
          onChange={(e) => setNewRequest(prev => ({ ...prev, asset: e.target.value }))}
          disabled={assetType === 'crypto' && !selectedChain}
        >
          <MenuItem value="">Select Asset</MenuItem>
          {filteredAssets.map((asset) => (
            <MenuItem key={asset.id} value={asset.asset}>
              {asset.name} ({asset.asset})
            </MenuItem>
          ))}
        </Select>
        {assetType === 'crypto' && !selectedChain && (
          <FormHelperText>Please select a chain first</FormHelperText>
        )}
      </FormControl>

      <TextField
  fullWidth
  margin="normal"
  label="Amount"
  type="text"
  value={newRequest.amount || ''}
  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Allow empty string, numbers, and one decimal point
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setNewRequest({
        ...newRequest,
        amount: value
      });
    }
    }}
    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!/[\d.]/.test(e.key)) {
        e.preventDefault();
      }
      // Prevent multiple decimal points
      if (e.key === '.' && (e.target as HTMLInputElement).value.includes('.')) {
        e.preventDefault();
      }
    }}
    inputProps={{
      inputMode: 'decimal',
    }}
  />

      <TextField
        fullWidth
        margin="normal"
        label="Description"
        multiline
        rows={3}
        value={newRequest.description || ''}
        onChange={(e) => setNewRequest(prev => ({ ...prev, description: e.target.value }))}
      />
    </>
  );

  const renderTransactionForm = () => {
    switch (newRequest.type) {
      case 'credit_user':
      case 'debit_user':
        return (
          <Box>
            <TextField
              fullWidth
              margin="normal"
              label="User ID"
              value={newRequest.userId || ''}
              onChange={(e) => setNewRequest(prev => ({ ...prev, userId: e.target.value }))}
            />
            {renderAssetTypeSelection()}
            {renderCommonFields()}
            <TextField
              fullWidth
              margin="normal"
              label="Settlement Details"
              multiline
              rows={3}
              value={newRequest.settlement_details || ''}
              onChange={(e) => setNewRequest(prev => ({ ...prev, settlement_details: e.target.value }))}
            />
          </Box>
        );

      case 'from_exchange':
        return (
          <Box>
            {renderExchangeSelect('From Exchange', newRequest.from_exchange, 'from_exchange')}
            {renderWalletSelect('To Wallet', newRequest.to_wallet, 'to_wallet')}
            {renderCommonFields()}
          </Box>
        );

      case 'to_exchange':
        return (
          <Box>
            {renderWalletSelect('From Wallet', newRequest.from_wallet, 'from_wallet')}
            {renderExchangeSelect('To Exchange', newRequest.to_exchange, 'to_exchange')}
            {renderCommonFields()}
          </Box>
        );

      case 'send_to_address':
        return (
          <Box>
            {renderWalletSelect('From Wallet', newRequest.from_wallet, 'from_wallet')}
            <TextField
              fullWidth
              margin="normal"
              label="To Address"
              value={newRequest.to_address || ''}
              onChange={(e) => setNewRequest(prev => ({ ...prev, to_address: e.target.value }))}
            />
            {renderCommonFields()}
          </Box>
        );

      case 'exchange_to_exchange':
        return (
          <Box>
            {renderExchangeSelect('From Exchange', newRequest.from_exchange, 'from_exchange')}
            {renderExchangeSelect('To Exchange', newRequest.to_exchange, 'to_exchange')}
            {renderCommonFields()}
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Transaction Requests and Approvals</Typography>
        <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
          New Transaction Request
        </Button>
      </Box>

      <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
        <Tab label="Pending Requests" />
        <Tab label="Approval Queue" />
        <Tab label="Historical Events" />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Asset</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {manualRequests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell>{request.id}</TableCell>
                  <TableCell>
                    <Chip
                      label={request.type.replace('_', ' ').toUpperCase()}
                      color={
                        request.type.includes('credit') ? 'success' :
                        request.type.includes('debit') ? 'error' :
                        'primary'
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {request.userId && <div>User: {request.userId}</div>}
                    {request.from_exchange && <div>From: {request.from_exchange}</div>}
                    {request.to_exchange && <div>To: {request.to_exchange}</div>}
                    {request.from_wallet && <div>From Wallet: {request.from_wallet}</div>}
                    {request.to_wallet && <div>To Wallet: {request.to_wallet}</div>}
                    {request.to_address && <div>To Address: {request.to_address}</div>}
                    {request.description && <div>Description: {request.description}</div>}
                    {request.settlement_details && <div>Settlement: {request.settlement_details}</div>}
                  </TableCell>
                  <TableCell>{request.amount}</TableCell>
                  <TableCell>
                    <Chip label={request.asset} />
                    {request.chain && (
                      <Chip
                        label={chains.find(c => c.id === parseInt(request.chain!))?.name || request.chain}
                        sx={{ ml: 1 }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={request.status}
                      color={
                        request.status === 'approved' ? 'success' :
                        request.status === 'rejected' ? 'error' :
                        'warning'
                      }
                    />
                  </TableCell>
                  <TableCell>{request.createdBy}</TableCell>
                  <TableCell>{new Date(request.createdAt).toLocaleString()}</TableCell>
                  <TableCell>
                    {request.status === 'pending' && (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          onClick={() => handleApprove(request.id)}
                        >
                          Approve
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={() => handleReject(request.id)}
                        >
                          Reject
                        </Button>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Create New Transaction Request</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <FormControl component="fieldset" sx={{ mt: 2 }}>
            <RadioGroup
              row
              value={newRequest.type}
              onChange={(e) => setNewRequest({
                ...initialRequest,
                type: e.target.value as TransactionType
              })}
            >
              <FormControlLabel value="credit_user" control={<Radio />} label="Credit User" />
              <FormControlLabel value="debit_user" control={<Radio />} label="Debit User" />
              <FormControlLabel value="from_exchange" control={<Radio />} label="From Exchange" />
              <FormControlLabel value="to_exchange" control={<Radio />} label="To Exchange" />
              <FormControlLabel value="send_to_address" control={<Radio />} label="Send to Address" />
              <FormControlLabel value="exchange_to_exchange" control={<Radio />} label="Exchange to Exchange" />
            </RadioGroup>
          </FormControl>

          {renderTransactionForm()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleCreateRequest} variant="contained" color="primary">
            Create Request
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

const TabPanel: React.FC<{
  children?: React.ReactNode;
  value: number;
  index: number;
}> = (props) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default TransactionRequests;

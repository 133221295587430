import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Alert,
  Box,
  SelectChangeEvent
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { Socket } from 'socket.io-client';

interface CreateWalletDialogProps {
  open: boolean;
  onClose: () => void;
  socket: Socket | null;
}

type WalletEntity = 'ALPHA NODE S.P.L' | 'ALPHA NODE SP. Z O.O.';

interface WalletFormData {
  walletName: string;
  walletDescription: string;
  walletEntity: WalletEntity;
  publicKeys?: string[];
}

interface WalletResponse {
  status?: string;
  error?: string;
  data?: {
    wallet_id: string;
    encrypted_wallet: string;
    encrypted_data_key: string;
    created_at: string;
    metadata: Record<string, unknown>;
  };
}

const CreateWalletDialog: React.FC<CreateWalletDialogProps> = ({ open, onClose, socket }) => {
  const [formData, setFormData] = useState<WalletFormData>({
    walletName: '',
    walletDescription: '',
    walletEntity: 'ALPHA NODE S.P.L',
    publicKeys: []
  });
  const [newKey, setNewKey] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const handleInputChange = (field: keyof Omit<WalletFormData, 'walletEntity' | 'publicKeys'>) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({
      ...formData,
      [field]: event.target.value
    });
  };

  const handleEntityChange = (event: SelectChangeEvent<WalletEntity>) => {
    setFormData({
      ...formData,
      walletEntity: event.target.value as WalletEntity
    });
  };

  const handleAddKey = () => {
    if (!newKey.trim()) return;

    // Basic RSA public key validation - making it more lenient
    if (!newKey.includes('PUBLIC KEY')) {
      setError('Invalid RSA public key format');
      return;
    }

    setFormData(prev => ({
      ...prev,
      publicKeys: [...(prev.publicKeys || []), newKey.trim()]
    }));
    setNewKey('');
    setError('');
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddKey();
    }
  };

  const handleRemoveKey = (index: number) => {
    setFormData(prev => ({
      ...prev,
      publicKeys: prev.publicKeys?.filter((_, i) => i !== index) || []
    }));
  };

  const handleSubmit = () => {
    if (!formData.walletName || !formData.walletEntity) {
      setError('Please fill in the required fields (Wallet Name and Entity)');
      return;
    }

    // Create submission data, omitting publicKeys if empty
    const submissionData = {
      ...formData,
      publicKeys: formData.publicKeys?.length ? formData.publicKeys : undefined
    };

    socket?.emit('createWallet', submissionData, (response: WalletResponse) => {
      if (response.status === 'success') {
        console.log('Wallet created:', response.data);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          onClose();
          resetForm();
        }, 2000);
      } else {
        setError(response.error || 'Failed to create wallet');
      }
    });
  };

  const resetForm = () => {
    setFormData({
      walletName: '',
      walletDescription: '',
      walletEntity: 'ALPHA NODE S.P.L',
      publicKeys: []
    });
    setNewKey('');
    setError('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New Wallet</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Wallet created successfully!
          </Alert>
        )}

        <TextField
          fullWidth
          label="Wallet Name"
          value={formData.walletName}
          onChange={handleInputChange('walletName')}
          margin="normal"
          required
        />

        <TextField
          fullWidth
          label="Wallet Description"
          value={formData.walletDescription}
          onChange={handleInputChange('walletDescription')}
          margin="normal"
          multiline
          rows={3}
        />

        <FormControl fullWidth margin="normal" required>
          <InputLabel>Wallet Entity</InputLabel>
          <Select<WalletEntity>
            value={formData.walletEntity}
            onChange={handleEntityChange}
            label="Wallet Entity"
          >
            <MenuItem value="ALPHA NODE S.P.L">ALPHA NODE S.P.L</MenuItem>
            <MenuItem value="ALPHA NODE SP. Z O.O.">ALPHA NODE SP. Z O.O.</MenuItem>
          </Select>
        </FormControl>

        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Public Keys for Backup Encryption (Optional)
        </Typography>

        <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
          <TextField
            fullWidth
            label="Add Public Key"
            value={newKey}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewKey(e.target.value)}
            onKeyPress={handleKeyPress}
            multiline
            rows={3}
            sx={{ mb: 1 }}
          />
          <Button
            variant="contained"
            onClick={handleAddKey}
            sx={{ minWidth: '100px', height: '40px' }}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </Box>

        <List>
          {formData.publicKeys?.map((key, index) => (
            <ListItem key={index} sx={{ bgcolor: 'background.paper', mb: 1 }}>
              <ListItemText
                primary={`Key ${index + 1}`}
                secondary={key.length > 50 ? `${key.substring(0, 50)}...` : key}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleRemoveKey(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create Wallet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateWalletDialog;

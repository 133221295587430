import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Typography,
  CircularProgress,
  Alert,
  Collapse,
  Box,
  Grid
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Socket } from "socket.io-client";

interface OrderBookEntry {
  price: number;
  amount: number;
}

interface OrderBook {
  bids: OrderBookEntry[];
  asks: OrderBookEntry[];
  timestamp: number;
}

interface SpreadData {
  symbol: string;
  topBid: number;
  topAsk: number;
  bidAmount: number;
  askAmount: number;
  spreadBps: number;
  timestamp: number;
}

interface DetailedOrderBook extends OrderBook {
  symbol: string;
}

interface PairInfo {
  symbol: string;
  baseAsset: string;
  quoteAsset: string;
}

interface FinerySpreadGridProps {
  socket: Socket | null;
}

type SortField = 'symbol' | 'topBid'| 'topAsk' | 'bidAmount' | 'askAmount' | 'spreadBps';
type SortDirection = 'asc' | 'desc';

interface OrderBookDetailProps {
  orderBook: DetailedOrderBook;
}

const OrderBookDetail: React.FC<OrderBookDetailProps> = ({ orderBook }) => {
  return (
    <Box sx={{ py: 2, px: 4, bgcolor: 'background.paper' }}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography variant="subtitle2" sx={{ mb: 1, color: 'success.main' }}>
            Bids
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Price</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBook.bids.slice(0, 10).map((bid, index) => {
                const total = orderBook.bids
                  .slice(0, index + 1)
                  .reduce((sum, item) => sum + item.amount, 0);
                return (
                  <TableRow key={`bid-${index}`}>
                    <TableCell sx={{ color: 'success.main', fontFamily: 'monospace' }}>
                      {bid.price.toString()}
                    </TableCell>
                    <TableCell align="right" sx={{ fontFamily: 'monospace' }}>
                      {bid.amount.toString()}
                    </TableCell>
                    <TableCell align="right" sx={{ fontFamily: 'monospace' }}>
                      {total.toFixed(4)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" sx={{ mb: 1, color: 'error.main' }}>
            Asks
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Price</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBook.asks.slice(0, 10).map((ask, index) => {
                const total = orderBook.asks
                  .slice(0, index + 1)
                  .reduce((sum, item) => sum + item.amount, 0);
                return (
                  <TableRow key={`ask-${index}`}>
                    <TableCell sx={{ color: 'error.main', fontFamily: 'monospace' }}>
                      {ask.price.toString()}
                    </TableCell>
                    <TableCell align="right" sx={{ fontFamily: 'monospace' }}>
                      {ask.amount.toString()}
                    </TableCell>
                    <TableCell align="right" sx={{ fontFamily: 'monospace' }}>
                      {total.toFixed(4)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  );
};

const FinerySpreadGrid: React.FC<FinerySpreadGridProps> = ({ socket }) => {
  const [rawSpreads, setRawSpreads] = useState<SpreadData[]>([]);
  const [orderBooks, setOrderBooks] = useState<Record<string, DetailedOrderBook>>({});
  const [expandedSymbol, setExpandedSymbol] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sortField, setSortField] = useState<SortField>('symbol');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [subscribedPairs, setSubscribedPairs] = useState<Set<string>>(new Set());

  const getSortedSpreads = useCallback((data: SpreadData[], field: SortField, direction: SortDirection) => {
    return [...data].sort((a, b) => {
      let comparison = 0;
      if (field === 'symbol') {
        comparison = a.symbol.localeCompare(b.symbol);
      } else {
        comparison = (a[field] || 0) - (b[field] || 0);
      }
      return direction === 'asc' ? comparison : -comparison;
    });
  }, []);

  const sortedSpreads = React.useMemo(() => {
    return getSortedSpreads(rawSpreads, sortField, sortDirection);
  }, [rawSpreads, sortField, sortDirection, getSortedSpreads]);

  useEffect(() => {
    if (!socket) return;

    const handleOrderBookUpdate = (data: { symbol: string } & OrderBook) => {
      if (!data.symbol) {
        console.warn('Received orderbook update without symbol:', data);
        return;
      }

      setRawSpreads(prev => {
        const newSpreads = [...prev];
        const index = newSpreads.findIndex(s => s.symbol === data.symbol);

        const topBid = data.bids[0]?.price || 0;
        const topAsk = data.asks[0]?.price || 0;
        const spreadBps = topBid ? ((topAsk - topBid) / topBid) * 10000 : 0;

        const newSpread: SpreadData = {
          symbol: data.symbol,
          topBid,
          topAsk,
          bidAmount: data.bids[0]?.amount || 0,
          askAmount: data.asks[0]?.amount || 0,
          spreadBps,
          timestamp: data.timestamp
        };

        if (index === -1) {
          newSpreads.push(newSpread);
        } else {
          newSpreads[index] = newSpread;
        }

        return newSpreads;
      });

      setOrderBooks(prev => ({
        ...prev,
        [data.symbol]: {
          ...data,
          symbol: data.symbol
        }
      }));

      setLoading(false);
    };

    const handleError = (errorMsg: string) => {
      setError(errorMsg);
      setLoading(false);
    };

    const handlePairs = (pairs: PairInfo[]) => {
      setRawSpreads(prev => {
        if (prev.length === 0) {
          return pairs.map(pair => ({
            symbol: pair.symbol,
            topBid: 0,
            topAsk: 0,
            bidAmount: 0,
            askAmount: 0,
            spreadBps: 0,
            timestamp: Date.now()
          }));
        }
        return prev;
      });

      pairs.forEach(pair => {
        if (!subscribedPairs.has(pair.symbol)) {
          console.log('Subscribing to:', pair.symbol);
          socket.emit('subscribeOrderBook', { symbol: pair.symbol });
          setSubscribedPairs(prev => new Set([...prev, pair.symbol]));
        }
      });
    };

    socket.emit('getFineryPairs');

    socket.on('fineryPairs', handlePairs);
    socket.on('orderBookUpdate', handleOrderBookUpdate);
    socket.on('fineryError', handleError);

    return () => {
      socket.off('fineryPairs');
      socket.off('orderBookUpdate');
      socket.off('fineryError');

      subscribedPairs.forEach(symbol => {
        socket.emit('unsubscribeOrderBook', { symbol });
      });
    };
  }, [socket, subscribedPairs]);

  const handleSort = (field: SortField) => {
    setSortDirection(prevDirection => {
      if (sortField !== field) return 'asc';
      return prevDirection === 'asc' ? 'desc' : 'asc';
    });
    setSortField(field);
  };

  const handleRowClick = (symbol: string) => {
    setExpandedSymbol(expandedSymbol === symbol ? null : symbol);
  };

  if (loading && sortedSpreads.length === 0) {
    return (
      <Paper sx={{ p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper sx={{ p: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 4 }}>
      <Typography variant="h6" gutterBottom>
        Finery Live Raw Spreads
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={40}></TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === 'symbol'}
                  direction={sortField === 'symbol' ? sortDirection : 'asc'}
                  onClick={() => handleSort('symbol')}
                >
                  Symbol
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Top Bid</TableCell>
              <TableCell align="right">Top Ask</TableCell>
              <TableCell align="right">Bid Amount</TableCell>
              <TableCell align="right">Ask Amount</TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortField === 'spreadBps'}
                  direction={sortField === 'spreadBps' ? sortDirection : 'asc'}
                  onClick={() => handleSort('spreadBps')}
                >
                  Spread (bps)
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSpreads.map((spread) => (
              <React.Fragment key={spread.symbol}>
                <TableRow
                  hover
                  onClick={() => handleRowClick(spread.symbol)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    {expandedSymbol === spread.symbol ? (
                      <KeyboardArrowDownIcon sx={{ fontSize: 20 }} />
                    ) : (
                      <KeyboardArrowRightIcon sx={{ fontSize: 20 }} />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {spread.symbol}
                  </TableCell>
                  <TableCell align="right" sx={{ color: 'success.main', fontFamily: 'monospace' }}>
                    {spread.topBid.toString()}
                  </TableCell>
                  <TableCell align="right" sx={{ color: 'error.main', fontFamily: 'monospace' }}>
                    {spread.topAsk.toString()}
                  </TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'monospace' }}>
                    {spread.bidAmount.toString()}
                  </TableCell>
                  <TableCell align="right" sx={{ fontFamily: 'monospace' }}>
                    {spread.askAmount.toString()}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      fontFamily: 'monospace',
                      fontWeight: 'bold',
                      fontSize: '1.1rem',
                      color: 'primary.main'
                    }}
                  >
                    {spread.spreadBps.toFixed(1)}
                  </TableCell>
                  <TableCell align="right">
                    {new Date(spread.timestamp).toLocaleTimeString()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={8} sx={{ p: 0, borderBottom: 'none' }}>
                    <Collapse in={expandedSymbol === spread.symbol}>
                      {orderBooks[spread.symbol] && (
                        <OrderBookDetail orderBook={orderBooks[spread.symbol]} />
                      )}
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default FinerySpreadGrid;

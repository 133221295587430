import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import {
  Paper, Typography, Tabs, Tab, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TablePagination, Collapse, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface User {
  id: number;
  email: string;
  organization: string;
  permissions: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface Address {
  id: string;
  asset: string;
  is_fiat: boolean;
  whitelisted: boolean;
}

interface FiatAddress extends Address {
  iban: string;
  swift: string;
  bank_name: string;
  bank_address: string;
  recipient: string;
}

interface CryptoAddress extends Address {
  address: string;
  memo?: string;
  classification: string;
  recipient_entity: string;
}


interface AccountDetailsProps {
  socket: Socket | null;
}

interface PaginatedData<T> {
  data: T[];
  total: number;
  page: number;
  pageSize: number;
}

interface AccountData {
  ledger: PaginatedData<LedgerEntry>;
  orders: PaginatedData<Order>;
  transactions: PaginatedData<Transaction>;
  account_logs: PaginatedData<AccountLog>;
  volumeData: VolumeData[];
  api_keys: ApiKey[];
  mfa_devices: MfaDevice[];
  users: PaginatedData<User>;
  addresses: PaginatedData<Address>;
}

interface LedgerEntry {
  id: number;
  date: string;
  asset: string;
  type: string;
  amount: number;
}

interface Order {
  id: number;
  date: string;
  symbol: string;
  side: string;
  amount: number;
  price: number;
  status: string;
  fills: Fill[];
}

interface Fill {
  id: string;
  amount: number;
  price: number;
}

interface Transaction {
  id: number;
  date: string;
  type: string;
  symbol: string;
  amount: number;
  price: number;
}

interface AccountLog {
  id: number;
  date: string;
  action: string;
  ip_address: string;
  user_agent: string;
}

interface VolumeData {
  date: string;
  tradingVolume: number;
  settlementVolume: number;
}

interface ApiKey {
  id: number;
  name: string;
  created_at: string;
  last_used: string;
  status: 'active' | 'disabled';
}

interface MfaDevice {
  id: number;
  type: string;
  added_at: string;
  last_used: string;
  status: 'active' | 'disabled';
}

const AccountDetails: React.FC<AccountDetailsProps> = ({ socket }) => {
  const [addressesPage, setAddressesPage] = useState(0);
  const [addressesRowsPerPage, setAddressesRowsPerPage] = useState(10);
  const [addressDialogOpen, setAddressDialogOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleAddressClick = (address: Address) => {
    setSelectedAddress(address);
    setAddressDialogOpen(true);
  };
  const [usersPage, setUsersPage] = useState(0);
  const [usersRowsPerPage, setUsersRowsPerPage] = useState(10);
  const { accountId } = useParams<{ accountId: string }>();
  const [value, setValue] = useState(0);
  const [accountData, setAccountData] = useState<AccountData | null>(null);
  const [expandedOrder, setExpandedOrder] = useState<number | false>(false);

  const [ledgerPage, setLedgerPage] = useState(0);
  const [ledgerRowsPerPage, setLedgerRowsPerPage] = useState(10);

  const [ordersPage, setOrdersPage] = useState(0);
  const [ordersRowsPerPage, setOrdersRowsPerPage] = useState(10);

  const [transactionsPage, setTransactionsPage] = useState(0);
  const [transactionsRowsPerPage, setTransactionsRowsPerPage] = useState(10);

  const [accountLogsPage, setAccountLogsPage] = useState(0);
  const [accountLogsRowsPerPage, setAccountLogsRowsPerPage] = useState(10);

  const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (socket && accountId) {
      fetchAccountDetails();
    }

    return () => {
      if (socket) {
        socket.off('accountDetailsData');
        socket.off('apiKeyDisabled');
        socket.off('mfaDeviceDisabled');
      }
    };
  }, [socket, accountId, ledgerPage, ledgerRowsPerPage, ordersPage, ordersRowsPerPage, transactionsPage, transactionsRowsPerPage, accountLogsPage, accountLogsRowsPerPage, usersPage, usersRowsPerPage, addressesPage, addressesRowsPerPage, startDate, endDate]);

  const fetchAccountDetails = () => {
    setIsLoading(true);
    socket?.emit('requestAccountDetails', accountId, {
      ledgerPage: ledgerPage + 1,
      ledgerRowsPerPage,
      ordersPage: ordersPage + 1,
      ordersRowsPerPage,
      transactionsPage: transactionsPage + 1,
      transactionsRowsPerPage,
      accountLogsPage: accountLogsPage + 1,
      accountLogsRowsPerPage,
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      usersPage: usersPage + 1,
      usersRowsPerPage,
      addressesPage: addressesPage + 1,
      addressesRowsPerPage,
    });

    socket?.on('accountDetailsData', (data: AccountData) => {
      setAccountData(data);
      setIsLoading(false);
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    setter: React.Dispatch<React.SetStateAction<number>>
  ) => {
    setter(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setter: React.Dispatch<React.SetStateAction<number>>,
    pageSetter: React.Dispatch<React.SetStateAction<number>>
  ) => {
    setter(parseInt(event.target.value, 10));
    pageSetter(0);
  };

  const handleDisableApiKey = (keyId: number) => {
    socket?.emit('disableApiKey', keyId);
    socket?.on('apiKeyDisabled', (response) => {
      if (response.success) {
        fetchAccountDetails();
      }
    });
  };

  const handleDisableMfaDevice = (deviceId: number) => {
    socket?.emit('disableMfaDevice', deviceId);
    socket?.on('mfaDeviceDisabled', (response) => {
      if (response.success) {
        fetchAccountDetails();
      }
    });
  };

  interface AddressDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  address: Address | null;
}

  const AddressDetailsDialog: React.FC<AddressDetailsDialogProps> = ({ open, onClose, address }) => {
    if (!address) return null;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{address.is_fiat ? 'Fiat Address Details' : 'Crypto Address Details'}</DialogTitle>
        <DialogContent>
          <Typography><strong>Asset:</strong> {address.asset}</Typography>
          <Typography><strong>Whitelisted:</strong> {address.whitelisted ? 'Yes' : 'No'}</Typography>
          {address.is_fiat ? (
            <>
              <Typography><strong>IBAN:</strong> {(address as FiatAddress).iban}</Typography>
              <Typography><strong>SWIFT:</strong> {(address as FiatAddress).swift}</Typography>
              <Typography><strong>Bank Name:</strong> {(address as FiatAddress).bank_name}</Typography>
              <Typography><strong>Bank Address:</strong> {(address as FiatAddress).bank_address}</Typography>
              <Typography><strong>Recipient:</strong> {(address as FiatAddress).recipient}</Typography>
            </>
          ) : (
            <>
              <Typography><strong>Address:</strong> {(address as CryptoAddress).address}</Typography>
              {(address as CryptoAddress).memo && (
                <Typography><strong>Memo:</strong> {(address as CryptoAddress).memo}</Typography>
              )}
              <Typography><strong>Classification:</strong> {(address as CryptoAddress).classification}</Typography>
              <Typography><strong>Recipient Entity:</strong> {(address as CryptoAddress).recipient_entity}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const exportToCSV = (data: any[], filename: string) => {
    const csvContent = "data:text/csv;charset=utf-8,"
      + Object.keys(data[0]).join(",") + "\n"
      + data.map(row => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Account Details: {accountId}
        </Typography>

        <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />

          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        {/* Volume Summary Chart */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Volume Summary</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={accountData?.volumeData ?? []}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="tradingVolume" stroke="#8884d8" name="Trading Volume" />
              <Line type="monotone" dataKey="settlementVolume" stroke="#82ca9d" name="Settlement Volume" />
            </LineChart>
          </ResponsiveContainer>
        </Box>

        <Tabs value={value} onChange={handleChange} aria-label="account tabs">
          <Tab label="Ledger" />
          <Tab label="Orders" />
          <Tab label="Transactions" />
          <Tab label="Account Logs" />
          <Tab label="API Keys" />
          <Tab label="2FA Devices" />
          <Tab label="Users" />
          <Tab label="Addresses" />
        </Tabs>

        {/* Ledger Tab */}
        <TabPanel value={value} index={0}>
          <Button onClick={() => exportToCSV(accountData?.ledger.data || [], 'ledger')} sx={{ mb: 2 }}>Export CSV</Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Asset</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountData?.ledger.data.map((entry) => (
                  <TableRow key={entry.id}>
                    <TableCell>{entry.date}</TableCell>
                    <TableCell>{entry.asset}</TableCell>
                    <TableCell>{entry.type}</TableCell>
                    <TableCell>{entry.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={accountData?.ledger.total ?? 0}
            page={ledgerPage}
            onPageChange={(event, newPage) => handleChangePage(event, newPage, setLedgerPage)}
            rowsPerPage={ledgerRowsPerPage}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setLedgerRowsPerPage, setLedgerPage)}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </TabPanel>

        {/* Orders Tab */}
        <TabPanel value={value} index={1}>
          <Button onClick={() => exportToCSV(accountData?.orders.data || [], 'orders')} sx={{ mb: 2 }}>Export CSV</Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Date</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Side</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountData?.orders.data.map((order) => (
                  <React.Fragment key={order.id}>
                    <TableRow>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setExpandedOrder(expandedOrder === order.id ? false : order.id)}
                        >
                          {expandedOrder === order.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>{order.date}</TableCell>
                      <TableCell>{order.symbol}</TableCell>
                      <TableCell>{order.side}</TableCell>
                      <TableCell>{order.amount}</TableCell>
                      <TableCell>{order.price}</TableCell>
                      <TableCell>{order.status}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={expandedOrder === order.id} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                              Fills
                            </Typography>
                            <Table size="small" aria-label="fills">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Fill ID</TableCell>
                                  <TableCell>Amount</TableCell>
                                  <TableCell>Price</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {order.fills.map((fill) => (
                                  <TableRow key={fill.id}>
                                    <TableCell>{fill.id}</TableCell>
                                    <TableCell>{fill.amount}</TableCell>
                                    <TableCell>{fill.price}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={accountData?.orders.total ?? 0}
            page={ordersPage}
            onPageChange={(event, newPage) => handleChangePage(event, newPage, setOrdersPage)}
            rowsPerPage={ordersRowsPerPage}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setOrdersRowsPerPage, setOrdersPage)}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </TabPanel>

        {/* Transactions Tab */}
        <TabPanel value={value} index={2}>
          <Button onClick={() => exportToCSV(accountData?.transactions.data || [], 'transactions')} sx={{ mb: 2 }}>Export CSV</Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountData?.transactions.data.map((transaction) => (
                  <TableRow key={transaction.id}>
                    <TableCell>{transaction.date}</TableCell>
                    <TableCell>{transaction.type}</TableCell>
                    <TableCell>{transaction.symbol}</TableCell>
                    <TableCell>{transaction.amount}</TableCell>
                    <TableCell>{transaction.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={accountData?.transactions.total ?? 0}
            page={transactionsPage}
            onPageChange={(event, newPage) => handleChangePage(event, newPage, setTransactionsPage)}
            rowsPerPage={transactionsRowsPerPage}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setTransactionsRowsPerPage, setTransactionsPage)}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </TabPanel>
{/* Account Logs Tab */}
        <TabPanel value={value} index={3}>
          <Button onClick={() => exportToCSV(accountData?.account_logs.data || [], 'account_logs')} sx={{ mb: 2 }}>Export CSV</Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>IP Address</TableCell>
                  <TableCell>User Agent</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountData?.account_logs.data.map((log) => (
                  <TableRow key={log.id}>
                    <TableCell>{log.date}</TableCell>
                    <TableCell>{log.action}</TableCell>
                    <TableCell>{log.ip_address}</TableCell>
                    <TableCell>{log.user_agent}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={accountData?.account_logs.total ?? 0}
            page={accountLogsPage}
            onPageChange={(event, newPage) => handleChangePage(event, newPage, setAccountLogsPage)}
            rowsPerPage={accountLogsRowsPerPage}
            onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setAccountLogsRowsPerPage, setAccountLogsPage)}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </TabPanel>

        {/* API Keys Tab */}
        <TabPanel value={value} index={4}>
          <Button onClick={() => exportToCSV(accountData?.api_keys || [], 'api_keys')} sx={{ mb: 2 }}>Export CSV</Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Last Used</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountData?.api_keys.map((key) => (
                  <TableRow key={key.id}>
                    <TableCell>{key.name}</TableCell>
                    <TableCell>{key.created_at}</TableCell>
                    <TableCell>{key.last_used}</TableCell>
                    <TableCell>{key.status}</TableCell>
                    <TableCell>
                      {key.status === 'active' && (
                        <Button onClick={() => handleDisableApiKey(key.id)}>Disable</Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

        {/* 2FA Devices Tab */}
        <TabPanel value={value} index={5}>
          <Button onClick={() => exportToCSV(accountData?.mfa_devices || [], 'mfa_devices')} sx={{ mb: 2 }}>Export CSV</Button>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Added At</TableCell>
                  <TableCell>Last Used</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountData?.mfa_devices.map((device) => (
                  <TableRow key={device.id}>
                    <TableCell>{device.type}</TableCell>
                    <TableCell>{device.added_at}</TableCell>
                    <TableCell>{device.last_used}</TableCell>
                    <TableCell>{device.status}</TableCell>
                    <TableCell>
                      {device.status === 'active' && (
                        <Button onClick={() => handleDisableMfaDevice(device.id)}>Disable</Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        {/* Users Tab */}
      <TabPanel value={value} index={6}>
        <Button onClick={() => exportToCSV(accountData?.users.data || [], 'account_users')} sx={{ mb: 2 }}>Export CSV</Button>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Organization</TableCell>
                <TableCell>Permissions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountData?.users.data.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.organization}</TableCell>
                  <TableCell>{user.permissions}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={accountData?.users.total ?? 0}
          page={usersPage}
          onPageChange={(event, newPage) => handleChangePage(event, newPage, setUsersPage)}
          rowsPerPage={usersRowsPerPage}
          onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setUsersRowsPerPage, setUsersPage)}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TabPanel>
        {/* Addresses Tab */}
        <TabPanel value={value} index={7}>
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : accountData?.addresses ? (
            <>
              <Button onClick={() => exportToCSV(accountData.addresses.data || [], 'addresses')} sx={{ mb: 2 }}>Export CSV</Button>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Asset</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Whitelisted</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {accountData.addresses.data.map((address) => (
                      <TableRow key={address.id}>
                        <TableCell>{address.asset}</TableCell>
                        <TableCell>{address.is_fiat ? 'Fiat' : 'Crypto'}</TableCell>
                        <TableCell>{address.whitelisted ? 'Yes' : 'No'}</TableCell>
                        <TableCell>
                          <Button onClick={() => handleAddressClick(address)}>View Details</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={accountData.addresses.total}
                page={addressesPage}
                onPageChange={(event, newPage) => handleChangePage(event, newPage, setAddressesPage)}
                rowsPerPage={addressesRowsPerPage}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setAddressesRowsPerPage, setAddressesPage)}
                rowsPerPageOptions={[5, 10, 25]}
              />
              <AddressDetailsDialog
                open={addressDialogOpen}
                onClose={() => setAddressDialogOpen(false)}
                address={selectedAddress}
              />
            </>
          ) : (
            <Typography>No address data available</Typography>
          )}
        </TabPanel>
      </Paper>
    </LocalizationProvider>
  );
};

export default AccountDetails;

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Paper, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import CryptoJS from 'crypto-js';

interface FeeTier {
  id: string;
  name: string;
  bps: number;
}

interface Account {
  id: string;
  type: 'master' | 'sub';
  balance: number;
  currency: string;
}

interface Organization {
  id: string;
  name: string;
  industry: string;
  accounts: Account[];
  feeTier: FeeTier;
}

interface KYBData {
  proofOfAddress: string;
  passport: string;
  directors: string[];
}

const OrganizationDetails: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [showKYB, setShowKYB] = useState(false);
  const [password, setPassword] = useState('');
  const [kybData, setKybData] = useState<KYBData | null>(null);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [feeTiers, setFeeTiers] = useState<FeeTier[]>([]);
  const [openFeeTierDialog, setOpenFeeTierDialog] = useState(false);

  useEffect(() => {
    // Fetch organization data from API
    // For now, we'll use dummy data
    setOrganization({
      id: organizationId || '',
      name: 'Acme Inc',
      industry: 'Technology',
      accounts: [
        { id: 'MA001', type: 'master', balance: 1000000, currency: 'USD' },
        { id: 'SA001', type: 'sub', balance: 500000, currency: 'USD' },
        { id: 'SA002', type: 'sub', balance: 250000, currency: 'EUR' },
      ],
      feeTier: { id: '1', name: 'Default', bps: 10 },
    });

    setFeeTiers([
      { id: '1', name: 'Default', bps: 10 },
      { id: '2', name: 'Premium', bps: 5 },
      { id: '3', name: 'VIP', bps: 1 },
    ]);
  }, [organizationId]);

  const handleFeeTierChange = (newTierId: string) => {
    const newTier = feeTiers.find(tier => tier.id === newTierId);
    if (newTier && organization) {
      setOrganization({ ...organization, feeTier: newTier });
      // In a real application, you would make an API call here to update the organization's fee tier
    }
    setOpenFeeTierDialog(false);
  };

  const handleKYBClick = () => {
    const storedPassword = localStorage.getItem('kybPassword');
    const passwordExpiry = localStorage.getItem('kybPasswordExpiry');

    if (storedPassword && passwordExpiry && new Date().getTime() < parseInt(passwordExpiry)) {
      setPassword(storedPassword);
      fetchKYBData(storedPassword);
    } else {
      setShowPasswordDialog(true);
    }
  };

  const handlePasswordSubmit = () => {
    localStorage.setItem('kybPassword', password);
    localStorage.setItem('kybPasswordExpiry', (new Date().getTime() + 24 * 60 * 60 * 1000).toString());
    setShowPasswordDialog(false);
    fetchKYBData(password);
  };

  const fetchKYBData = (pwd: string) => {
    // Mock API call to fetch encrypted KYB data
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
      proofOfAddress: 'https://example.com/proof_of_address.jpg',
      passport: 'https://example.com/passport.jpg',
      directors: ['https://example.com/director1.jpg', 'https://example.com/director2.jpg']
    }), pwd).toString();

    // Decrypt the data
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, pwd);
      const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
      setKybData(decryptedData);
      setShowKYB(true);
    } catch (error) {
      console.error('Decryption failed:', error);
      alert('Invalid password or corrupted data');
    }
  };

  if (!organization) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Organization: {organization.name}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Industry: {organization.industry}
      </Typography>
      <Button onClick={handleKYBClick} variant="contained" sx={{ mb: 2 }}>
        View KYB Data
      </Button>

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h6">Fee Tier</Typography>
          <Typography>Current Tier: {organization?.feeTier.name} ({organization?.feeTier.bps} bps)</Typography>
          <Button onClick={() => setOpenFeeTierDialog(true)}>Change Fee Tier</Button>
        </CardContent>
      </Card>

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Accounts
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Account ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organization.accounts.map((account) => (
              <TableRow key={account.id}>
                <TableCell>{account.id}</TableCell>
                <TableCell>{account.type}</TableCell>
                <TableCell>{account.balance.toLocaleString()}</TableCell>
                <TableCell>{account.currency}</TableCell>
                <TableCell>
                  <Button component={Link} to={`/account/${account.id}`} variant="outlined" size="small">
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={showPasswordDialog} onClose={() => setShowPasswordDialog(false)}>
        <DialogTitle>Enter KYB Decryption Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPasswordDialog(false)}>Cancel</Button>
          <Button onClick={handlePasswordSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showKYB} onClose={() => setShowKYB(false)} maxWidth="md" fullWidth>
        <DialogTitle>KYB Data for {organization.name}</DialogTitle>
        <DialogContent>
          {kybData && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              <Card sx={{ maxWidth: 345 }}>
                <CardContent>
                  <Typography variant="h6">Proof of Address</Typography>
                  <img src={kybData.proofOfAddress} alt="Proof of Address" style={{ maxWidth: '100%', height: 'auto' }} />
                </CardContent>
              </Card>
              <Card sx={{ maxWidth: 345 }}>
                <CardContent>
                  <Typography variant="h6">Passport</Typography>
                  <img src={kybData.passport} alt="Passport" style={{ maxWidth: '100%', height: 'auto' }} />
                </CardContent>
              </Card>
              {kybData.directors.map((director, index) => (
                <Card key={index} sx={{ maxWidth: 345 }}>
                  <CardContent>
                    <Typography variant="h6">Director {index + 1}</Typography>
                    <img src={director} alt={`Director ${index + 1}`} style={{ maxWidth: '100%', height: 'auto' }} />
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowKYB(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openFeeTierDialog} onClose={() => setOpenFeeTierDialog(false)}>
        <DialogTitle>Change Fee Tier</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Fee Tier</InputLabel>
            <Select
              value={organization?.feeTier.id}
              onChange={(e) => handleFeeTierChange(e.target.value as string)}
            >
              {feeTiers.map((tier) => (
                <MenuItem key={tier.id} value={tier.id}>{tier.name} ({tier.bps} bps)</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFeeTierDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default OrganizationDetails;

import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, CircularProgress, Box } from '@mui/material';
import { format } from 'date-fns';

interface ClientVolumeStatsProps {
  socket: Socket | null;
}

interface ClientVolumeData {
  clientId: string;
  clientName: string;
  volume: number;
}

const ClientVolumeStats: React.FC<ClientVolumeStatsProps> = ({ socket }) => {
  const [clientVolumes, setClientVolumes] = useState<ClientVolumeData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (socket) {
      socket.emit('requestClientVolumes', {
        date: format(new Date(), 'yyyy-MM-dd'),
        page: page + 1,
        rowsPerPage
      });

      socket.on('clientVolumeData', (data: ClientVolumeData[]) => {
        console.log("Received client volume data:", data);
        if (Array.isArray(data)) {
          setClientVolumes(data);
        } else {
          console.error("Received data is not an array:", data);
          setClientVolumes([]);
        }
        setIsLoading(false);
      });
    }

    return () => {
      if (socket) {
        socket.off('clientVolumeData');
      }
    };
  }, [socket, page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!clientVolumes || clientVolumes.length === 0) {
    return (
      <Paper sx={{ p: 2 }}>
        <Typography>No client volume data available.</Typography>
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>Client Volumes</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client ID</TableCell>
              <TableCell>Client Name</TableCell>
              <TableCell>Volume</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientVolumes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client) => (
              <TableRow key={client.clientId}>
                <TableCell>{client.clientId}</TableCell>
                <TableCell>{client.clientName}</TableCell>
                <TableCell>${client.volume.toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={clientVolumes.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ClientVolumeStats;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Chip
} from '@mui/material';
import { Socket } from 'socket.io-client';
import { Add as AddIcon } from '@mui/icons-material';
import CreateWalletDialog from './CreateWalletDialog';

interface WalletsProps {
  socket: Socket | null;
}

interface Wallet {
  wallet_id: string;
  created_at: string;
  metadata: {
    wallet_name: string;
    wallet_description: string;
    wallet_entity: string;
  };
}

const WalletsSection: React.FC<WalletsProps> = ({ socket }) => {
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const navigate = useNavigate();

  const fetchWallets = () => {
    if (socket) {
      socket.emit('getWallets');
    }
  };

  useEffect(() => {
    if (socket) {
      // Initial fetch
      fetchWallets();

      // Listen for wallets data
      socket.on('walletsData', (data: Wallet[]) => {
        setWallets(data);
      });

      // Listen for new wallet creation success
      socket.on('walletCreated', (response: { status: string, data?: Wallet }) => {
        if (response.status === 'success') {
          // Refresh the wallets list
          fetchWallets();
        }
      });
    }

    return () => {
      if (socket) {
        socket.off('walletsData');
        socket.off('walletCreated');
      }
    };
  }, [socket]);

  const handleWalletClick = (walletId: string) => {
    navigate(`/transactionserver/wallets/${walletId}`);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  const handleCreateWalletClose = () => {
    setOpenCreateDialog(false);
    // Refresh the wallets list after closing the dialog
    fetchWallets();
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" component="h2">
          Wallet Management
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenCreateDialog(true)}
        >
          Create New Wallet
        </Button>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Wallet ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Entity</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wallets.map((wallet) => (
              <TableRow
                key={wallet.wallet_id}
                hover
                onClick={() => handleWalletClick(wallet.wallet_id)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{wallet.wallet_id}</TableCell>
                <TableCell>{wallet.metadata.wallet_name}</TableCell>
                <TableCell>{wallet.metadata.wallet_description}</TableCell>
                <TableCell>
                  <Chip
                    label={wallet.metadata.wallet_entity}
                    color={wallet.metadata.wallet_entity === 'ALPHA NODE S.P.L' ? 'primary' : 'secondary'}
                  />
                </TableCell>
                <TableCell>{formatDate(wallet.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CreateWalletDialog
        open={openCreateDialog}
        onClose={handleCreateWalletClose}
        socket={socket}
      />
    </Paper>
  );
};

export default WalletsSection;

import React, { useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import MoneyIcon from '@mui/icons-material/Money';
import { Navigate, BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  CssBaseline,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BarChartIcon from '@mui/icons-material/BarChart';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import UsersSection from './sections/users/UsersSection';
import StaffUserDetails from './sections/users/StaffUserDetails';
import AuditLog from './sections/audit/AuditLog';
import OrganizationsPage from './sections/organizations/OrganizationsPage';
import OrganizationDetails from './sections/organizations/OrganizationDetails';
import AccountDetails from './sections/accounts/AccountDetails';
import TransactionRequests from './sections/requests/TransactionRequests';
import Stats from './sections/stats/Stats';
import LoginForm from './sections/auth/LoginForm';
import SystemConfiguration from "./sections/system/SystemConfiguration";
import {AccountBalanceWallet} from "@mui/icons-material";
import TransactionServerConfig from "./sections/transactionserver/TransactionServerConfig";
import CreateWalletDialog from './sections/transactionserver//CreateWalletDialog';
import WalletsSection from "./sections/transactionserver/WalletsSection";
import WalletDetails from "./sections/transactionserver/WalletDetails";
import AddressesSection from "./sections/transactionserver/AddressesSection";
import FinerySpreads from "./sections/pricing/FinerySpreads";
import FinerySpreadGrid from "./sections/pricing/FinerySpreadGrid";

const drawerWidth = 240;

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0bec5',
    },
  },
});

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DashboardContent: React.FC<{ socket: Socket | null; onLogout: () => void }> = ({ socket, onLogout }) => {

  const navigate = useNavigate();
  const [statsMenuOpen, setStatsMenuOpen] = useState(false);
  const [fineryMenuOpen, setFineryMenuOpen] = useState(false);

  const handleStatsClick = () => {
    setStatsMenuOpen(!statsMenuOpen);
  };

  const handleStatsNavigation = (path: string) => {
    navigate(path);
  };

  const [transactionServerMenuOpen, setTransactionServerMenuOpen] = useState(false);

  const handleTransactionServerClick = () => {
    setTransactionServerMenuOpen(!transactionServerMenuOpen);
  };

  const handleTransactionServerNavigation = (path: string) => {
    navigate(path);
  };

  const NavigationItems = () => (
    <List>
      <ListItem disablePadding>
        <ListItemButton onClick={() => {
          setFineryMenuOpen(!fineryMenuOpen);
        }}>
          <ListItemIcon>
            <MoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Pricing" />
          {fineryMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={fineryMenuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/finery/spreads')}>
            <ListItemText primary="Finery Spreads" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/finery/spread-grid')}>
          <ListItemText primary="Spread Grid" />
        </ListItemButton>
        </List>
      </Collapse>
       <ListItem disablePadding>
        <ListItemButton onClick={handleTransactionServerClick}>
          <ListItemIcon>
            <AccountBalanceWallet />
          </ListItemIcon>
          <ListItemText primary="Transaction Server" />
          {transactionServerMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={transactionServerMenuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => handleTransactionServerNavigation('/transactionserver/config')}>
            <ListItemText primary="Configuration" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => handleTransactionServerNavigation('/transactionserver/wallets')}>
            <ListItemText primary="Wallets" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => handleTransactionServerNavigation('/transactionserver/addresses')}>
          <ListItemText primary="Addresses" />
        </ListItemButton>
        </List>
      </Collapse>
        {/*<List component="div" disablePadding>*/}
        {/*  <ListItemButton sx={{ pl: 4 }} onClick={() => handleTransactionServerNavigation('/transactionserver/transactions')}>*/}
        {/*    <ListItemText primary="Transactions" />*/}
        {/*  </ListItemButton>*/}
        {/*</List>*/}
        {/*<List component="div" disablePadding>*/}
        {/*  <ListItemButton sx={{ pl: 4 }} onClick={() => handleTransactionServerNavigation('/transactionserver/kyt')}>*/}
        {/*    <ListItemText primary="KYT Scans" />*/}
        {/*  </ListItemButton>*/}
        {/*</List>*/}
        {/*<List component="div" disablePadding>*/}
        {/*  <ListItemButton sx={{ pl: 4 }} onClick={() => handleTransactionServerNavigation('/transactionserver/balance')}>*/}
        {/*    <ListItemText primary="Wallet Balances" />*/}
        {/*  </ListItemButton>*/}
        {/*</List>*/}
      {/*<ListItem disablePadding>*/}
      {/*  <ListItemButton onClick={handleStatsClick}>*/}
      {/*    <ListItemIcon>*/}
      {/*      <BarChartIcon />*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText primary="Stats" />*/}
      {/*    {statsMenuOpen ? <ExpandLess /> : <ExpandMore />}*/}
      {/*  </ListItemButton>*/}
      {/*</ListItem>*/}
      {/*<Collapse in={statsMenuOpen} timeout="auto" unmountOnExit>*/}
      {/*  <List component="div" disablePadding>*/}
      {/*    <ListItemButton sx={{ pl: 4 }} onClick={() => handleStatsNavigation('/stats/daily-volume')}>*/}
      {/*      <ListItemText primary="Daily Volume" />*/}
      {/*    </ListItemButton>*/}
      {/*    <ListItemButton sx={{ pl: 4 }} onClick={() => handleStatsNavigation('/stats/monthly-volume')}>*/}
      {/*      <ListItemText primary="Monthly Volume" />*/}
      {/*    </ListItemButton>*/}
      {/*    <ListItemButton sx={{ pl: 4 }} onClick={() => handleStatsNavigation('/stats/client-volume')}>*/}
      {/*      <ListItemText primary="Client Volume" />*/}
      {/*    </ListItemButton>*/}
      {/*    <ListItemButton sx={{ pl: 4 }} onClick={() => handleStatsNavigation('/stats/trading-pair-volume')}>*/}
      {/*      <ListItemText primary="Trading Pair Volume" />*/}
      {/*    </ListItemButton>*/}
      {/*    <ListItemButton sx={{ pl: 4 }} onClick={() => handleStatsNavigation('/stats/entity-liabilities')}>*/}
      {/*      <ListItemText primary="Entity Liabilities" />*/}
      {/*    </ListItemButton>*/}
      {/*    <ListItemButton sx={{ pl: 4 }} onClick={() => handleStatsNavigation('/stats/real-time-spread')}>*/}
      {/*      <ListItemText primary="Real-time Spread" />*/}
      {/*    </ListItemButton>*/}
      {/*  </List>*/}
      {/*</Collapse>*/}

      {[
        { text: 'Approval Queue', icon: <AssignmentIcon />, path: '/transaction-requests' },
        // { text: 'Organizations', icon: <BusinessIcon />, path: '/organizations' },
        { text: 'Staff Users', icon: <PeopleIcon />, path: '/users' },
        // { text: 'System Configuration', icon: <PrecisionManufacturingIcon />, path: '/system-configuration' },
        { text: 'Staff Audit', icon: <VerifiedUserIcon />, path: '/audit' },
      ].map((item) => (
        <ListItem key={item.text} disablePadding>
          <ListItemButton onClick={() => navigate(item.path)}>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            OA Staff Admin
          </Typography>
          <Button color="inherit" onClick={onLogout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <NavigationItems />
      </Drawer>
      <Main open={true}>
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Routes>
            <Route path="/stats/*" element={<Stats socket={socket} />} />
            <Route path="/system-configuration" element={<SystemConfiguration />} />
            <Route path="/organizations" element={<OrganizationsPage socket={socket}/>} />
            <Route path="/organization/:organizationId" element={<OrganizationDetails />} />
            <Route path="/users" element={<UsersSection socket={socket}/>} />
            <Route path="/user/:userId" element={<StaffUserDetails socket={socket} />} />
            <Route path="/account/:accountId" element={<AccountDetails socket={socket}/>} />
            <Route path="/transaction-requests" element={<TransactionRequests socket={socket} />} />
            <Route path="/transactionserver/config" element={<TransactionServerConfig socket={socket} />} />
            <Route path="/transactionserver/wallets" element={<WalletsSection socket={socket} />} />
            <Route path="/transactionserver/wallets/:walletId" element={<WalletDetails socket={socket} />} />
            <Route path="/transactionserver/addresses" element={<AddressesSection socket={socket} />} />
            <Route path="/audit" element={<AuditLog socket={socket}/>} />
            <Route path="/finery/spreads" element={<FinerySpreads socket={socket} />} />
            <Route path="/finery/spread-grid" element={<FinerySpreadGrid socket={socket} />} />
            <Route path="/" element={<Navigate to="/stats" replace />} />
          </Routes>
        </Container>
      </Main>
    </Box>
  );
};

const Dashboard: React.FC = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('authToken');

    let newSocket: Socket;
    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    if (!socketUrl) {
      console.error('Socket URL is not defined in environment variables');
      setIsLoading(false);
      return;
    }
    if (token) {
      console.log("Connecting with existing token")
      newSocket = io(socketUrl, {path: '/socket.io', extraHeaders: { Authorization: `Bearer ${token}`}});
    } else {
      console.log("Connecting as anonymous user")
      newSocket = io(socketUrl, {path: '/socket.io'});
    }
    setIsLoading(false);

    setSocket(newSocket);
    newSocket.on('connectResponse', (response: { authenticated: boolean }) => {
      setIsLoggedIn(response.authenticated);
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsLoggedIn(false);
    // Optionally, emit a logout event to the server
    if (socket) {
      socket.emit('logout');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {!isLoggedIn ? (
        <LoginForm socket={socket} onLoginSuccess={handleLoginSuccess} theme={darkTheme} />
      ) : (
        <DashboardContent socket={socket} onLogout={handleLogout} />
      )}
    </ThemeProvider>
  );
};
export default Dashboard;

import React, { useState } from 'react';
import {
  Paper, Typography, Tabs, Tab, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Switch, FormControlLabel, Select, MenuItem, InputLabel, FormControl, IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface Asset {
  id: string;
  name: string;
  fullName: string;
  precision: number;
  allowDeposits: boolean;
  allowWithdrawals: boolean;
  isFiat: boolean;
  image: string;
  chains: Chain[];
  collateralValue: number;
}

interface Chain {
  name: string;
  contractDecimals: number;
  contractAddress: string;
  custodian: 'INTERNAL_WALLET' | 'FIREBLOCKS' | 'CRYPTOAPIS';
}

interface FeeTier {
  id: string;
  name: string;
  bps: number;
}

interface Market {
  symbol: string;
  lotSize: number;
  tickSize: number;
  minAmount: number;
  maxAmount: number;
  marginable: boolean;
  baseAsset: string;
  quoteAsset: string;
}

interface ChainConfig {
  name: string;
  nativeAsset: string;
  custodians: ('INTERNAL_WALLET' | 'FIREBLOCKS' | 'CRYPTOAPIS')[];
}

const SystemConfiguration: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [markets, setMarkets] = useState<Market[]>([]);
  const [chains, setChains] = useState<ChainConfig[]>([]);
  const [systemEnabled, setSystemEnabled] = useState({ trading: true, withdrawals: true });
  const [baseFee, setBaseFee] = useState({ regularPairsBps: 10, stablePairsBps: 1 });
  const [openAssetDialog, setOpenAssetDialog] = useState(false);
  const [currentAsset, setCurrentAsset] = useState<Asset | null>(null);
  const [feeTiers, setFeeTiers] = useState<FeeTier[]>([
    { id: '1', name: 'Default', bps: 10 },
    { id: '2', name: 'Medium', bps: 5 },
    { id: '3', name: 'VIP', bps: 1 },
  ]);
  const [currentFeeTier, setCurrentFeeTier] = useState<FeeTier | null>(null);
  const [openFeeTierDialog, setOpenFeeTierDialog] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleAddFeeTier = () => {
    setCurrentFeeTier(null);
    setOpenFeeTierDialog(true);
  };

  const handleEditFeeTier = (tier: FeeTier) => {
    setCurrentFeeTier(tier);
    setOpenFeeTierDialog(true);
  };

  const handleDeleteFeeTier = (id: string) => {
    setFeeTiers(feeTiers.filter(tier => tier.id !== id));
  };

  const handleSaveFeeTier = (tier: FeeTier) => {
    if (currentFeeTier) {
      setFeeTiers(feeTiers.map(t => t.id === tier.id ? tier : t));
    } else {
      setFeeTiers([...feeTiers, { ...tier, id: Date.now().toString() }]);
    }
    setOpenFeeTierDialog(false);
  };

  const handleAddAsset = () => {
    setCurrentAsset(null);
    setOpenAssetDialog(true);
  };

  const handleEditAsset = (asset: Asset) => {
    setCurrentAsset(asset);
    setOpenAssetDialog(true);
  };

  const handleDeleteAsset = (assetId: string) => {
    setAssets(assets.filter(asset => asset.id !== assetId));
  };

  const handleSaveAsset = (asset: Asset) => {
    if (currentAsset) {
      setAssets(assets.map(a => a.id === asset.id ? asset : a));
    } else {
      setAssets([...assets, { ...asset, id: Date.now().toString() }]);
    }
    setOpenAssetDialog(false);
  };

  const handleMarketChange = (index: number, field: keyof Market, value: any) => {
    const newMarkets = [...markets];
    newMarkets[index] = { ...newMarkets[index], [field]: value };
    setMarkets(newMarkets);
  };

  const handleChainChange = (index: number, field: keyof ChainConfig, value: any) => {
    const newChains = [...chains];
    newChains[index] = { ...newChains[index], [field]: value };
    setChains(newChains);
  };

  const handleSystemEnabledChange = (field: 'trading' | 'withdrawals') => {
    setSystemEnabled({ ...systemEnabled, [field]: !systemEnabled[field] });
  };

  const handleBaseFeeChange = (field: 'regularPairsBps' | 'stablePairsBps', value: number) => {
    setBaseFee({ ...baseFee, [field]: value });
  };

  return (
    <Paper sx={{ width: '100%', typography: 'body1' }}>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="system configuration tabs">
        <Tab label="Assets" />
        <Tab label="Markets" />
        <Tab label="Chains" />
        <Tab label="System Enabled" />
        <Tab label="Base Fee" />
        <Tab label="Fee Tiers" />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Typography variant="h6" gutterBottom>Assets Configuration</Typography>
        <Button startIcon={<AddIcon />} onClick={handleAddAsset}>Add Asset</Button>
        {assets.map(asset => (
          <Box key={asset.id} sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
            <Typography variant="subtitle1">{asset.name} ({asset.fullName})</Typography>
            <Typography>Precision: {asset.precision}</Typography>
            <Typography>Deposits: {asset.allowDeposits ? 'Allowed' : 'Not Allowed'}</Typography>
            <Typography>Withdrawals: {asset.allowWithdrawals ? 'Allowed' : 'Not Allowed'}</Typography>
            <Typography>Type: {asset.isFiat ? 'Fiat' : 'Crypto'}</Typography>
            <Typography>Collateral Value: {asset.collateralValue}</Typography>
            {asset.image && <img src={asset.image} alt={asset.name} style={{ maxWidth: 50, maxHeight: 50 }} />}
            <Box sx={{ mt: 1 }}>
              <IconButton onClick={() => handleEditAsset(asset)}><EditIcon /></IconButton>
              <IconButton onClick={() => handleDeleteAsset(asset.id)}><DeleteIcon /></IconButton>
            </Box>
          </Box>
        ))}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Typography variant="h6" gutterBottom>Markets Configuration</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Symbol</TableCell>
                <TableCell>Lot Size</TableCell>
                <TableCell>Tick Size</TableCell>
                <TableCell>Min Amount</TableCell>
                <TableCell>Max Amount</TableCell>
                <TableCell>Marginable</TableCell>
                <TableCell>Base Asset</TableCell>
                <TableCell>Quote Asset</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {markets.map((market, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField value={market.symbol} onChange={(e) => handleMarketChange(index, 'symbol', e.target.value)} />
                  </TableCell>
                  <TableCell>
                    <TextField type="number" value={market.lotSize} onChange={(e) => handleMarketChange(index, 'lotSize', parseFloat(e.target.value))} />
                  </TableCell>
                  <TableCell>
                    <TextField type="number" value={market.tickSize} onChange={(e) => handleMarketChange(index, 'tickSize', parseFloat(e.target.value))} />
                  </TableCell>
                  <TableCell>
                    <TextField type="number" value={market.minAmount} onChange={(e) => handleMarketChange(index, 'minAmount', parseFloat(e.target.value))} />
                  </TableCell>
                  <TableCell>
                    <TextField type="number" value={market.maxAmount} onChange={(e) => handleMarketChange(index, 'maxAmount', parseFloat(e.target.value))} />
                  </TableCell>
                  <TableCell>
                    <Checkbox checked={market.marginable} onChange={(e) => handleMarketChange(index, 'marginable', e.target.checked)} />
                  </TableCell>
                  <TableCell>
                    <TextField value={market.baseAsset} onChange={(e) => handleMarketChange(index, 'baseAsset', e.target.value)} />
                  </TableCell>
                  <TableCell>
                    <TextField value={market.quoteAsset} onChange={(e) => handleMarketChange(index, 'quoteAsset', e.target.value)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button startIcon={<AddIcon />} onClick={() => setMarkets([...markets, { symbol: '', lotSize: 0, tickSize: 0, minAmount: 0, maxAmount: 0, marginable: false, baseAsset: '', quoteAsset: '' }])}>
          Add Market
        </Button>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <Typography variant="h6" gutterBottom>Chains Configuration</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Chain Name</TableCell>
                <TableCell>Chain Native Asset</TableCell>
                <TableCell>Custodians</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chains.map((chain, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField value={chain.name} onChange={(e) => handleChainChange(index, 'name', e.target.value)} />
                  </TableCell>
                  <TableCell>
                    <TextField value={chain.nativeAsset} onChange={(e) => handleChainChange(index, 'nativeAsset', e.target.value)} />
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel>Custodians</InputLabel>
                      <Select
                        multiple
                        value={chain.custodians}
                        onChange={(e) => handleChainChange(index, 'custodians', e.target.value as ('INTERNAL_WALLET' | 'FIREBLOCKS' | 'CRYPTOAPIS')[])}
                      >
                        <MenuItem value="INTERNAL_WALLET">INTERNAL_WALLET</MenuItem>
                        <MenuItem value="FIREBLOCKS">FIREBLOCKS</MenuItem>
                        <MenuItem value="CRYPTOAPIS">CRYPTOAPIS</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button startIcon={<AddIcon />} onClick={() => setChains([...chains, { name: '', nativeAsset: '', custodians: [] }])}>
          Add Chain
        </Button>
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
        <Typography variant="h6" gutterBottom>System Enabled Configuration</Typography>
        <FormControlLabel
          control={<Switch checked={systemEnabled.trading} onChange={() => handleSystemEnabledChange('trading')} />}
          label="Trading Enabled"
        />
        <FormControlLabel
          control={<Switch checked={systemEnabled.withdrawals} onChange={() => handleSystemEnabledChange('withdrawals')} />}
          label="Withdrawals Enabled"
        />
      </TabPanel>

      <TabPanel value={tabValue} index={4}>
        <Typography variant="h6" gutterBottom>Base Fee Configuration</Typography>
        <TextField
          label="Regular Pairs (bps)"
          type="number"
          value={baseFee.regularPairsBps}
          onChange={(e) => handleBaseFeeChange('regularPairsBps', parseInt(e.target.value))}
          sx={{ mr: 2 }}
        />
        <TextField
          label="Stable Pairs (bps)"
          type="number"
          value={baseFee.stablePairsBps}
          onChange={(e) => handleBaseFeeChange('stablePairsBps', parseInt(e.target.value))}
        />
      </TabPanel>

       <TabPanel value={tabValue} index={5}>
        <Typography variant="h6" gutterBottom>Fee Tiers Configuration</Typography>
        <Button startIcon={<AddIcon />} onClick={handleAddFeeTier}>Add Fee Tier</Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Fee (bps)</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {feeTiers.map((tier) => (
                <TableRow key={tier.id}>
                  <TableCell>{tier.name}</TableCell>
                  <TableCell>{tier.bps}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditFeeTier(tier)}><EditIcon /></IconButton>
                    <IconButton onClick={() => handleDeleteFeeTier(tier.id)}><DeleteIcon /></IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <Dialog open={openAssetDialog} onClose={() => setOpenAssetDialog(false)}>
        <DialogTitle>{currentAsset ? 'Edit Asset' : 'Add Asset'}</DialogTitle>
        <DialogContent>
          {/* Asset dialog content */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAssetDialog(false)}>Cancel</Button>
          <Button onClick={() => handleSaveAsset(currentAsset as Asset)}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openFeeTierDialog} onClose={() => setOpenFeeTierDialog(false)}>
        <DialogTitle>{currentFeeTier ? 'Edit Fee Tier' : 'Add Fee Tier'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            defaultValue={currentFeeTier?.name}
          />
          <TextField
            label="Fee (bps)"
            type="number"
            fullWidth
            margin="normal"
            defaultValue={currentFeeTier?.bps}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFeeTierDialog(false)}>Cancel</Button>
          <Button onClick={() => handleSaveFeeTier(currentFeeTier as FeeTier)}>Save</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default SystemConfiguration;

import React, { useState, useEffect, useCallback } from 'react';
import { Socket } from 'socket.io-client';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Box,
} from '@mui/material';

interface AuditEvent {
  id: string;
  user: string;
  action: string;
  timestamp: number;
  details: any;
}

interface AuditLogProps {
  socket: Socket | null;
}

const AuditLog: React.FC<AuditLogProps> = ({ socket }) => {
  const [events, setEvents] = useState<AuditEvent[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const fetchAuditLogs = useCallback(() => {
    if (socket) {
      socket.emit('getAuditLogs', { page, rowsPerPage });
    }
  }, [socket, page, rowsPerPage]);

  useEffect(() => {
    if (socket) {
      fetchAuditLogs();

      socket.on('auditLogData', (data: { events: AuditEvent[], totalCount: number }) => {
        setEvents(data.events);
        setTotalCount(data.totalCount);
      });
    }

    return () => {
      if (socket) {
        socket.off('auditLogData');
      }
    };
  }, [socket, fetchAuditLogs]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatTimestamp = (timestamp: number) => {
    return new Date(timestamp).toLocaleString();
  };

  const renderDetails = (details: any) => {
    return (
      <pre style={{ maxWidth: '100%', overflow: 'auto', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
        {JSON.stringify(details, null, 2)}
      </pre>
    );
  };

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
        <Typography variant="h6" gutterBottom component="div" sx={{ p: 2 }}>
          Audit Log
        </Typography>
        <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell width="15%">User</TableCell>
                <TableCell width="15%">Action</TableCell>
                <TableCell width="20%">Timestamp</TableCell>
                <TableCell width="50%">Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={event.id}>
                  <TableCell>{event.user}</TableCell>
                  <TableCell>{event.action}</TableCell>
                  <TableCell>{formatTimestamp(event.timestamp)}</TableCell>
                  <TableCell>{renderDetails(event.details)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default AuditLog;

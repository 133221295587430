import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tabs,
  Tab,
  Box,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Switch,
  FormHelperText,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import {Asset, Chain} from "./types";
import {formatUnixTimestamp} from "../util/DateFormat";
import { Edit as EditIcon } from '@mui/icons-material';

interface TransactionServerConfigProps {
  socket: Socket | null;
}

const TransactionServerConfig: React.FC<TransactionServerConfigProps> = ({ socket }) => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [currentAsset, setCurrentAsset] = useState<Asset | null>(null);
  const [chains, setChains] = useState<Chain[]>([]);
  const [deleteChainDialog, setDeleteChainDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [openAssetDialog, setOpenAssetDialog] = useState(false);
  const [deleteAssetDialog, setDeleteAssetDialog] = useState(false);
  const [assetToDelete, setAssetToDelete] = useState<Asset | null>(null);
  const [chainToDelete, setChainToDelete] = useState<Chain | null>(null);
  const [openChainDialog, setOpenChainDialog] = useState(false);
    const [newAsset, setNewAsset] = useState<Partial<Asset>>({
    is_fiat: false,
    contract_decimals: 18, // Default for crypto
  });
  const [newChain, setNewChain] = useState<Partial<Chain>>({});
  const [errors, setErrors] = useState<{
    name?: string;
    asset?: string;
    chain_id?: string;
    contract_address?: string;
    contract_decimals?: string;
  }>({});
  const handleDeleteAssetConfirmation = (asset: Asset) => {
    setAssetToDelete(asset);
    setDeleteAssetDialog(true);
  };
  const handleDeleteChainConfirmation = (chain: Chain) => {
    setChainToDelete(chain);
    setDeleteChainDialog(true);
  };

  const validateAssetForm = () => {
    const newErrors: typeof errors = {};

    if (!newAsset.name?.trim()) {
      newErrors.name = 'Name is required';
    }
    if (!newAsset.asset?.trim()) {
      newErrors.asset = 'Asset symbol is required';
    }
    if (!newAsset.is_fiat) {
      if (!newAsset.chain_id) {
        newErrors.chain_id = 'Chain is required for crypto assets';
      }
      if (!newAsset.contract_address?.trim()) {
        newErrors.contract_address = 'Contract address is required for crypto assets';
      }
    }
    if (newAsset.contract_decimals === undefined || newAsset.contract_decimals < 0) {
      newErrors.contract_decimals = 'Valid decimals are required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (socket) {
      socket.emit('getTransactionServerConfig');
      socket.on('transactionServerConfig', (config: { assets: Asset[], chains: Chain[] }) => {
        setAssets(config.assets);
        setChains(config.chains);
      });
    }

    return () => {
      if (socket) {
        socket.off('transactionServerConfig');
      }
    };
  }, [socket]);


  const handleAddAsset = () => {
  if (!validateAssetForm()) return;

  if (socket && newAsset.name) {
    const assetData = newAsset.is_fiat
      ? {
          name: newAsset.name,
          asset: newAsset.asset,
          contract_decimals: newAsset.contract_decimals,
          is_fiat: true,
        }
      : newAsset;

    if (currentAsset) {
      socket.emit('updateTransactionServerAsset', {
        asset_id: currentAsset.id,
        ...assetData
      }, (response: { success: boolean, asset?: Asset }) => {
        if (response.success && response.asset) {
          setAssets(prevAssets =>
            prevAssets.map(a =>
              a.id === currentAsset.id ? response.asset! : a
            )
          );
          handleCloseDialog();
        }
      });
    } else {
      socket.emit('addTransactionServerAsset', assetData, (response: { success: boolean, asset?: Asset }) => {
        if (response.success && response.asset) {
          setAssets(prevAssets => [...prevAssets, response.asset!]);
          handleCloseDialog();
        }
      });
    }
  }
};

  const handleEditAsset = (asset: Asset) => {
    setCurrentAsset(asset);
    setNewAsset({
      ...asset,
      chain_id: asset.chain_id,
    });
    setOpenAssetDialog(true);
  };

  const handleDeleteAsset = () => {
    if (socket && assetToDelete) {
      socket.emit('deleteTransactionServerAsset', assetToDelete.id, (response: { success: boolean }) => {
        if (response.success) {
          setAssets(assets.filter(asset => asset.id !== assetToDelete.id));
          setDeleteAssetDialog(false);
          setAssetToDelete(null);
        }
      });
    }
  }

  const handleAddChain = () => {
    if (socket && newChain.name) {
      socket.emit('addTransactionServerChain', newChain, (response: { success: boolean, chain?: Chain }) => {
        if (response.success && response.chain) {
          setChains([...chains, response.chain]);
          setOpenChainDialog(false);
          setNewChain({});
        }
      });
    }
  };
  const handleAssetTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isFiat = event.target.checked;
    setNewAsset({
      ...newAsset,
      is_fiat: isFiat,
      chain_id: isFiat ? undefined : newAsset.chain_id,
      contract_address: isFiat ? undefined : newAsset.contract_address,
      contract_decimals: isFiat ? 2 : 8, // Default to 2 for fiat, 8 for crypto
    });
  };
  const handleCloseDialog = () => {
    setOpenAssetDialog(false);
    setCurrentAsset(null);
    setNewAsset({ is_fiat: false, contract_decimals: 8 });
    setErrors({});
  };
  const handleDeleteChain = () => {
    if (socket && chainToDelete) {
      socket.emit('deleteTransactionServerChain', chainToDelete.id, (response: { success: boolean }) => {
        if (response.success) {
          setChains(chains.filter(c => c.id !== chainToDelete.id));
          setDeleteChainDialog(false);
          setChainToDelete(null);
        }
      });
    }
  }

   return (
    <Paper sx={{ width: '100%', typography: 'body1' }}>
      <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="config tabs">
        <Tab label="Assets" />
        <Tab label="Chains" />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Button variant="contained" onClick={() => setOpenAssetDialog(true)} sx={{ mb: 2 }}>
          Add Asset
        </Button>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Asset</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Chain</TableCell>
                <TableCell>Contract Address</TableCell>
                <TableCell>Decimals</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assets.map((asset) => (
                <TableRow key={asset.id}>
                  <TableCell>{asset.id}</TableCell>
                  <TableCell>{asset.name}</TableCell>
                  <TableCell>{asset.asset}</TableCell>
                  <TableCell>{asset.is_fiat ? 'Fiat' : 'Crypto'}</TableCell>
                  <TableCell>{asset.is_fiat ? '-' : asset.chain}</TableCell>
                  <TableCell>
                    {asset.is_fiat ? '-' : (
                      <Typography
                        sx={{
                          maxWidth: 200,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontFamily: 'monospace',
                        }}
                      >
                        {asset.contract_address}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>{asset.contract_decimals}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditAsset(asset)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteAssetConfirmation(asset)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Button variant="contained" onClick={() => setOpenChainDialog(true)} sx={{ mb: 2 }}>
          Add Chain
        </Button>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>UTXO</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chains.map((chain) => (
                <TableRow key={chain.id}>
                  <TableCell>{chain.id}</TableCell>
                  <TableCell>{chain.name}</TableCell>
                  <TableCell>{chain.is_utxo ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{formatUnixTimestamp(chain.created_at)}</TableCell>
                  <TableCell>{formatUnixTimestamp(chain.updated_at)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteChainConfirmation(chain)} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <Dialog open={openChainDialog} onClose={() => setOpenChainDialog(false)}>
        <DialogTitle>Add New Chain</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={newChain.name || ''}
            onChange={(e) => setNewChain({ ...newChain, name: e.target.value })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newChain.is_utxo || false}
                onChange={(e) => setNewChain({ ...newChain, is_utxo: e.target.checked })}
              />
            }
            label="Is UTXO"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChainDialog(false)}>Cancel</Button>
          <Button onClick={handleAddChain}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteChainDialog} onClose={() => setDeleteChainDialog(false)}>
        <DialogTitle>Delete Chain</DialogTitle>
        <DialogContent>
          <Typography>Are you sure? Chain will be marked as inactive in database</Typography>
          {chainToDelete && (
            <Box mt={2}>
              <Typography variant="subtitle1">Chain Details:</Typography>
              <Typography>ID: {chainToDelete.id}</Typography>
              <Typography>Name: {chainToDelete.name}</Typography>
              <Typography>UTXO: {chainToDelete.is_utxo ? 'Yes' : 'No'}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteChainDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteChain}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAssetDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{currentAsset ? 'Edit Asset' : 'Add New Asset'}</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                checked={newAsset.is_fiat}
                onChange={handleAssetTypeChange}
              />
            }
            label="Fiat Asset"
          />

          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={newAsset.name || ''}
            onChange={(e) => setNewAsset({ ...newAsset, name: e.target.value })}
            error={!!errors.name}
            helperText={errors.name}
          />

          <TextField
            margin="dense"
            label="Asset Symbol"
            fullWidth
            value={newAsset.asset || ''}
            onChange={(e) => setNewAsset({ ...newAsset, asset: e.target.value })}
            error={!!errors.asset}
            helperText={errors.asset}
          />

          {!newAsset.is_fiat && (
            <FormControl fullWidth margin="dense">
              <InputLabel>Chain</InputLabel>
              <Select
                value={newAsset.chain_id || ''}
                onChange={(e) => setNewAsset({ ...newAsset, chain_id: e.target.value as number })}
                error={!!errors.chain_id}
              >
                {chains.map((chain) => (
                  <MenuItem key={chain.id} value={chain.id}>{chain.name}</MenuItem>
                ))}
              </Select>
              {errors.chain_id && <FormHelperText error>{errors.chain_id}</FormHelperText>}
            </FormControl>
          )}

          {!newAsset.is_fiat && (
            <TextField
              margin="dense"
              label="Contract Address"
              fullWidth
              value={newAsset.contract_address || ''}
              onChange={(e) => setNewAsset({ ...newAsset, contract_address: e.target.value })}
              error={!!errors.contract_address}
              helperText={errors.contract_address}
            />
          )}

          <TextField
            margin="dense"
            label={`${newAsset.is_fiat ? 'Fiat' : 'Contract'} Decimals`}
            fullWidth
            type="number"
            value={newAsset.contract_decimals || ''}
            onChange={(e) => setNewAsset({ ...newAsset, contract_decimals: parseInt(e.target.value) })}
            error={!!errors.contract_decimals}
            helperText={errors.contract_decimals}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAddAsset}>{currentAsset ? 'Save' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteAssetDialog} onClose={() => setDeleteAssetDialog(false)}>
        <DialogTitle>Delete Asset</DialogTitle>
        <DialogContent>
          <Typography>Are you sure? Asset will be marked as inactive in database</Typography>
          {assetToDelete && (
            <Box mt={2}>
              <Typography variant="subtitle1">Asset Details:</Typography>
              <Typography>ID: {assetToDelete.id}</Typography>
              <Typography>Name: {assetToDelete.name}</Typography>
              <Typography>Asset: {assetToDelete.asset}</Typography>
              <Typography>Type: {assetToDelete.is_fiat ? 'Fiat' : 'Crypto'}</Typography>
              {!assetToDelete.is_fiat && (
                <>
                  <Typography>Chain: {assetToDelete.chain}</Typography>
                  <Typography>Contract Address: {assetToDelete.contract_address}</Typography>
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAssetDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteAsset}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default TransactionServerConfig;

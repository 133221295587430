import React, { useState } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import {
  Paper, Typography, List, ListItem, ListItemText, ListItemIcon, Collapse
} from '@mui/material';

import DailyVolumeStats from './DailyVolumeStats';
import MonthlyVolumeStats from './MonthlyVolumeStats';
import ClientVolumeStats from './ClientVolumeStats';
import TradingPairVolumeStats from './TradingPairVolumeStats';
import EntityLiabilitiesStats from './EntityLiabilitiesStats';
import RealTimeSpreadStats from './RealTimeSpreadStats';
import {Socket} from "socket.io-client";

interface StatsProps {
  socket: Socket | null;
}

const Stats: React.FC<StatsProps> = ({ socket }) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Routes>
        <Route path="/daily-volume" element={<DailyVolumeStats socket={socket} />} />
        <Route path="/monthly-volume" element={<MonthlyVolumeStats socket={socket} />} />
        <Route path="/client-volume" element={<ClientVolumeStats socket={socket} />} />
        <Route path="/trading-pair-volume" element={<TradingPairVolumeStats socket={socket} />} />
        <Route path="/entity-liabilities" element={<EntityLiabilitiesStats socket={socket} />} />
        <Route path="/real-time-spread" element={<RealTimeSpreadStats socket={socket} />} />
      </Routes>
    </Paper>
  );
};

export default Stats;

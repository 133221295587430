import React, { useState, useEffect, useRef } from 'react';
import { Socket } from 'socket.io-client';
import {
  Box, Typography, TextField, Button, Paper, ThemeProvider
} from '@mui/material';

interface TwoFASetupProps {
  socket: Socket | null;
  username: string;
  onSetupComplete: () => void;
  theme: any;
}

const TwoFASetup: React.FC<TwoFASetupProps> = ({ socket, username, onSetupComplete, theme }) => {
  const [qrCodeSvg, setQrCodeSvg] = useState<string | null>(null);
  const [secret, setSecret] = useState<string | null>(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const setupRequested = useRef(false);

  useEffect(() => {
    if (socket && !setupRequested.current) {
      console.log("Requesting 2fa setup data");
      setupRequested.current = true;
      socket.emit('request_2fa_setup', username);

      socket.on('2fa_setup_data', (data: { qr_code_svg: string, secret: string }) => {
          console.log("Received 2fa_setup_data: " + JSON.stringify(data));
          setQrCodeSvg(data.qr_code_svg);
          setSecret(data.secret);
      });

      socket.on('2fa_setup_response', (response: { success: boolean, message?: string }) => {
        if (response.success) {
          onSetupComplete();
        } else {
          setError(response.message || 'Failed to verify 2FA code');
        }
      });

      return () => {
        socket.off('2fa_setup_data');
        socket.off('2fa_setup_response');
      };

    }else if (socket) {
      socket.on('2fa_setup_data', (data: { qr_code_svg: string, secret: string }) => {
          console.log("Received 2fa_setup_data: " + JSON.stringify(data));
          setQrCodeSvg(data.qr_code_svg);
          setSecret(data.secret);
        });

        socket.on('2fa_setup_response', (response: { success: boolean, message?: string }) => {
          if (response.success) {
            onSetupComplete();
          } else {
            setError(response.message || 'Failed to verify 2FA code');
          }
        });

        return () => {
          socket.off('2fa_setup_data');
          socket.off('2fa_setup_response');
        };
    }
  }, [socket, username, onSetupComplete]);

  const handleVerify = () => {
    if (socket) {
      socket.emit('verify_2fa_setup', { username, token: verificationCode });
    }
  };

return (
    <ThemeProvider theme={theme}>
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, margin: 'auto', mt: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Set up Two-Factor Authentication
        </Typography>
        <Box sx={{ my: 2 }}>
          <Typography variant="body1" paragraph>
            1. Scan this QR code with your authenticator app:
          </Typography>
          {qrCodeSvg ? (
            <Box sx={{ width: '100%', maxWidth: 200, margin: 'auto' }}>
              <img
                src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(qrCodeSvg)}`}
                alt="2FA QR Code"
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
          ) : (
            <Typography>Loading QR code...</Typography>
          )}
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography variant="body1" paragraph>
            2. If you can't scan the QR code, enter this secret key manually:
          </Typography>
          <Typography variant="body2" sx={{ fontFamily: 'monospace', wordBreak: 'break-all' }}>
            {secret || 'Loading secret...'}
          </Typography>
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography variant="body1" paragraph>
            3. Enter the verification code from your authenticator app:
          </Typography>
          <TextField
            fullWidth
            label="Verification Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            margin="normal"
          />
          {error && (
            <Typography color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleVerify}
          disabled={!verificationCode}
        >
          Verify and Complete Setup
        </Button>
      </Paper>
    </ThemeProvider>
  );
};

export default TwoFASetup;

import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import {
  Paper, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TablePagination, Button, TextField, Collapse, IconButton
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface EntityAssetsLiabilitiesStatsProps {
  socket: Socket | null;
}

interface EntityData {
  asset: string;
  amount: number;
}

interface EntityAssetsLiabilities {
  'ALPHA NODE S.P.L': {
    assets: EntityData[];
    liabilities: EntityData[];
  };
  'ALPHA NODE SP. Z O.O.': {
    assets: EntityData[];
    liabilities: EntityData[];
  };
}

interface AssetBalance {
  asset: string;
  entity: 'ALPHA NODE S.P.L' | 'ALPHA NODE SP. Z O.O.';
  balance: number;
  liabilities: number;
  net: number;
  venues: { [key: string]: number };
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

const EntityAssetsLiabilitiesStats: React.FC<EntityAssetsLiabilitiesStatsProps> = ({ socket }) => {
  const [entityData, setEntityData] = useState<EntityAssetsLiabilities>({
    'ALPHA NODE S.P.L': { assets: [], liabilities: [] },
    'ALPHA NODE SP. Z O.O.': { assets: [], liabilities: [] }
  });
  const [assetBalances, setAssetBalances] = useState<AssetBalance[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [expandedRow, setExpandedRow] = useState<string | false>(false);

  useEffect(() => {
    if (socket) {
      const dateString = format(selectedDate || new Date(), 'yyyy-MM-dd');
      socket.emit('requestEntityAssetsLiabilities', dateString);
      socket.emit('requestAssetBalances', dateString);

      socket.on('entityAssetsLiabilitiesData', (data: EntityAssetsLiabilities) => setEntityData(data));
      socket.on('assetBalancesData', (data: AssetBalance[]) => setAssetBalances(data));
    }

    return () => {
      if (socket) {
        socket.off('entityAssetsLiabilitiesData');
        socket.off('assetBalancesData');
      }
    };
  }, [socket, selectedDate]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const exportToCSV = (data: any[], filename: string) => {
    const csvContent = "data:text/csv;charset=utf-8,"
      + Object.keys(data[0]).join(",") + "\n"
      + data.map(row => Object.values(row).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderPieChart = (data: EntityData[], title: string) => (
    <Box sx={{ height: 300, mt: 2, width: '50%' }}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            dataKey="amount"
            nameKey="asset"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Entity Assets and Liabilities</Typography>

        <Box sx={{ mb: 2 }}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <Button
            onClick={() => exportToCSV(assetBalances, `entity_assets_liabilities_${format(selectedDate || new Date(), 'yyyy-MM-dd')}`)}
            sx={{ ml: 2 }}
          >
            Export CSV
          </Button>
        </Box>

        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {renderPieChart(entityData['ALPHA NODE S.P.L'].assets, 'ALPHA NODE S.P.L Assets')}
          {renderPieChart(entityData['ALPHA NODE S.P.L'].liabilities, 'ALPHA NODE S.P.L Liabilities')}
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {renderPieChart(entityData['ALPHA NODE SP. Z O.O.'].assets, 'ALPHA NODE SP. Z O.O. Assets')}
          {renderPieChart(entityData['ALPHA NODE SP. Z O.O.'].liabilities, 'ALPHA NODE SP. Z O.O. Liabilities')}
        </Box>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Asset Balances and Liabilities</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Asset</TableCell>
                <TableCell>Entity</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Liabilities</TableCell>
                <TableCell>Net</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assetBalances.map((row) => (
                <React.Fragment key={`${row.asset}-${row.entity}`}>
                  <TableRow>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setExpandedRow(expandedRow === `${row.asset}-${row.entity}` ? false : `${row.asset}-${row.entity}`)}
                      >
                        {expandedRow === `${row.asset}-${row.entity}` ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.asset}</TableCell>
                    <TableCell>{row.entity}</TableCell>
                    <TableCell>{row.balance.toFixed(8)}</TableCell>
                    <TableCell>{row.liabilities.toFixed(8)}</TableCell>
                    <TableCell>{row.net.toFixed(8)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={expandedRow === `${row.asset}-${row.entity}`} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Venue Breakdown
                          </Typography>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>Venue</TableCell>
                                <TableCell>Balance</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.entries(row.venues).map(([venue, balance]) => (
                                <TableRow key={venue}>
                                  <TableCell component="th" scope="row">
                                    {venue}
                                  </TableCell>
                                  <TableCell>{balance.toFixed(8)}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </LocalizationProvider>
  );
};

export default EntityAssetsLiabilitiesStats;

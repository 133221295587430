import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Paper, Typography, Box, Card, CardContent, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TablePagination, FormGroup, FormControlLabel,
  Checkbox, Button, Snackbar, Alert
} from '@mui/material';
import { Socket } from "socket.io-client";
import { formatUnixTimestamp } from "../util/DateFormat";

interface UserDetail {
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  permissions: string[];
  active: boolean;
  is_staff: boolean;
  last_login: number;
}

interface AuditEvent {
  id: string;
  user: string;
  action: string;
  timestamp: number;
  details: any;
}

interface Permission {
  id: number;
  permission: string;
}

interface StaffDetailProps {
  socket: Socket | null;
}

const StaffUserDetails: React.FC<StaffDetailProps> = ({ socket }) => {
  const [events, setEvents] = useState<AuditEvent[]>([]);
  const { userId } = useParams<{ userId: string }>();
  const [userDetails, setUserDetails] = useState<UserDetail | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [allPermissions, setAllPermissions] = useState<Permission[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  });

  const fetchUserDetails = useCallback(() => {
    if (socket && userId) {
      socket.emit('getUserDetails', { userId });
    }
  }, [socket, userId]);

  const fetchAuditLogs = useCallback(() => {
    if (socket && userId) {
      socket.emit('getAuditLogs', { page, rowsPerPage, userId });
    }
  }, [socket, page, rowsPerPage, userId]);

  const fetchAllPermissions = useCallback(() => {
    if (socket) {
      socket.emit('requestPermissions');
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      fetchUserDetails();
      fetchAuditLogs();
      fetchAllPermissions();

      socket.on('userDetails', (data: UserDetail) => {
        setUserDetails(data);
        setSelectedPermissions(data.permissions);
      });

      socket.on('auditLogData', (data: { events: AuditEvent[], totalCount: number }) => {
        setEvents(data.events);
        setTotalCount(data.totalCount);
      });

      socket.on('permissionsData', (data: { permissions: Permission[] }) => {
        setAllPermissions(data.permissions);
      });
    }

    return () => {
      if (socket) {
        socket.off('userDetails');
        socket.off('auditLogData');
        socket.off('permissionsData');
      }
    };
  }, [socket, fetchUserDetails, fetchAuditLogs, fetchAllPermissions]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatTimestamp = (timestamp: number) => {
    return new Date(timestamp).toLocaleString();
  };

  const renderDetails = (details: any) => {
    return (
      <pre style={{ maxWidth: '300px', overflow: 'auto', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
        {JSON.stringify(details, null, 2)}
      </pre>
    );
  };

  const handlePermissionChange = (permission: string) => {
    setSelectedPermissions(prev =>
      prev.includes(permission)
        ? prev.filter(p => p !== permission)
        : [...prev, permission]
    );
  };

  const handleUpdatePermissions = () => {
    if (socket && userId) {
      const permissionIds = selectedPermissions.map(perm =>
        allPermissions.find(p => p.permission === perm)?.id
      ).filter((id): id is number => id !== undefined);

      socket.emit('updateUserPermissions', { userId, permissions: permissionIds }, (response: { success: boolean, error?: string }) => {
        if (response.success) {
          setSnackbar({ open: true, message: 'Permissions updated successfully', severity: 'success' });
          fetchUserDetails(); // Refresh user details
        } else {
          setSnackbar({ open: true, message: response.error || 'Failed to update permissions', severity: 'error' });
        }
      });
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (!userDetails) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Staff User Details: {userDetails.email}
      </Typography>

      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: 3, mb: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h6">General Information</Typography>
            <Typography>User ID: {userDetails.user_id}</Typography>
            <Typography>Active: {userDetails.active ? 'Yes' : 'No'}</Typography>
            <Typography>Staff: {userDetails.is_staff ? 'Yes' : 'No'}</Typography>
            <Typography>Last Login: {formatUnixTimestamp(userDetails.last_login)}</Typography>
            <Typography>
              Current Permissions: {userDetails.permissions.join(', ')}
            </Typography>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h6">Permissions</Typography>
            <FormGroup>
              {allPermissions.map((permission) => (
                <FormControlLabel
                  key={permission.id}
                  control={
                    <Checkbox
                      checked={selectedPermissions.includes(permission.permission)}
                      onChange={() => handlePermissionChange(permission.permission)}
                    />
                  }
                  label={permission.permission}
                />
              ))}
            </FormGroup>
            <Button variant="contained" color="primary" onClick={handleUpdatePermissions} sx={{ mt: 2 }}>
              Update Permissions
            </Button>
          </CardContent>
        </Card>
      </Box>

      <Typography variant="h6" gutterBottom>
        User Audit Log
      </Typography>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={event.id}>
                <TableCell>{event.action}</TableCell>
                <TableCell>{formatTimestamp(event.timestamp)}</TableCell>
                <TableCell>{renderDetails(event.details)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default StaffUserDetails;

import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { Paper, Typography, ToggleButtonGroup, ToggleButton, Box, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface SpreadData {
  timestamp: number;
  bidPrice: number;
  askPrice: number;
  spread: number;
}

interface RealTimeSpreadStatsProps {
  socket: Socket | null;
}

const RealTimeSpreadStats: React.FC<RealTimeSpreadStatsProps> = ({ socket }) => {
  const [timePeriod, setTimePeriod] = useState<string>('1h');
  const [chartData, setChartData] = useState<SpreadData[]>([]);
  const [symbol, setSymbol] = useState<string>('BTC/USD');

  const symbols = ['BTC/USD', 'ETH/USD', 'XRP/USD'];

  useEffect(() => {
    if (socket) {
      socket.emit('requestSpreadHistory', { symbol, period: timePeriod });

      socket.on('spreadHistoryData', (data: SpreadData[]) => {
        setChartData(data);
      });

      socket.on('spreadUpdate', (newData: SpreadData) => {
        setChartData(prevData => [...prevData.slice(-99), newData]);
      });
    }

    return () => {
      if (socket) {
        socket.off('spreadHistoryData');
        socket.off('spreadUpdate');
      }
    };
  }, [socket, timePeriod, symbol]);

  const handleTimePeriodChange = (event: React.MouseEvent<HTMLElement>, newTimePeriod: string | null) => {
    if (newTimePeriod !== null) {
      setTimePeriod(newTimePeriod);
      socket?.emit('requestSpreadHistory', { symbol, period: newTimePeriod });
    }
  };

  const handleSymbolChange = (event: SelectChangeEvent<string>) => {
    const newSymbol = event.target.value;
    setSymbol(newSymbol);
    socket?.emit('requestSpreadHistory', { symbol: newSymbol, period: timePeriod });
  };

  const formatXAxis = (tickItem: number) => {
    const date = new Date(tickItem);
    if (timePeriod === '1h') {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (timePeriod === '1d') {
      return date.toLocaleString([], { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Real-time Spread Changes
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <ToggleButtonGroup
          value={timePeriod}
          exclusive
          onChange={handleTimePeriodChange}
          aria-label="time period"
        >
          <ToggleButton value="1h" aria-label="1 hour">
            1h
          </ToggleButton>
          <ToggleButton value="1d" aria-label="1 day">
            1d
          </ToggleButton>
          <ToggleButton value="7d" aria-label="7 days">
            7d
          </ToggleButton>
        </ToggleButtonGroup>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="symbol-select-label">Symbol</InputLabel>
          <Select
            labelId="symbol-select-label"
            value={symbol}
            onChange={handleSymbolChange}
            label="Symbol"
          >
            {symbols.map((sym) => (
              <MenuItem key={sym} value={sym}>{sym}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="timestamp"
            tickFormatter={formatXAxis}
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="left"
            domain={['auto', 'auto']}
            label={{ value: 'Price', angle: -90, position: 'insideLeft' }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            domain={[0, 'dataMax']}
            label={{ value: 'Spread (bps)', angle: 90, position: 'insideRight' }}
          />
          <Tooltip
            labelFormatter={(label) => new Date(label).toLocaleString()}
            formatter={(value: number, name: string) => [value.toFixed(2), name]}
          />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="bidPrice" stroke="#8884d8" name="Bid Price" dot={false} />
          <Line yAxisId="left" type="monotone" dataKey="askPrice" stroke="#82ca9d" name="Ask Price" dot={false} />
          <Line yAxisId="right" type="monotone" dataKey="spread" stroke="#ffc658" name="Spread (bps)" dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default RealTimeSpreadStats;

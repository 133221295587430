import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { Socket } from 'socket.io-client';

interface EditWalletDialogProps {
  open: boolean;
  onClose: () => void;
  socket: Socket | null;
  wallet: {
    wallet_id: string;
    metadata: {
      wallet_name: string;
      wallet_description: string;
      wallet_entity: string;
    };
  };
  onUpdate: () => void;
}

type WalletEntity = 'ALPHA NODE S.P.L' | 'ALPHA NODE SP. Z O.O.';

interface WalletMetadata {
  wallet_name: string;
  wallet_description: string;
  wallet_entity: WalletEntity;
}

const EditWalletDialog: React.FC<EditWalletDialogProps> = ({
  open,
  onClose,
  socket,
  wallet,
  onUpdate
}) => {
  const [metadata, setMetadata] = useState<WalletMetadata>({
    wallet_name: wallet.metadata.wallet_name,
    wallet_description: wallet.metadata.wallet_description,
    wallet_entity: wallet.metadata.wallet_entity as WalletEntity
  });

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleTextChange = (field: keyof Pick<WalletMetadata, 'wallet_name' | 'wallet_description'>) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMetadata(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleEntityChange = (event: SelectChangeEvent<WalletEntity>) => {
    setMetadata(prev => ({
      ...prev,
      wallet_entity: event.target.value as WalletEntity
    }));
  };

  const handleSubmit = () => {
    if (!socket) {
      setError('No socket connection available');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    const updateData = {
      wallet_id: wallet.wallet_id,
      metadata: {
        wallet_name: metadata.wallet_name,
        wallet_description: metadata.wallet_description,
        wallet_entity: metadata.wallet_entity
      }
    };

    console.log('Emitting updateWalletMetadata with data:', updateData);

    socket.emit('updateWalletMetadata', updateData, (response: { status: string; message: string }) => {
      setIsSubmitting(false);
      console.log('Received response:', response);

      if (response.status === 'success') {
        setSuccess(true);
        onUpdate();
        setTimeout(() => {
          setSuccess(false);
          onClose();
        }, 1500);
      } else {
        setError(response.message || 'Failed to update wallet');
      }
    });
  };

  const handleClose = () => {
    setError(null);
    setSuccess(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Wallet Details</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Wallet updated successfully!
          </Alert>
        )}

        <TextField
          fullWidth
          label="Wallet Name"
          value={metadata.wallet_name}
          onChange={handleTextChange('wallet_name')}
          margin="normal"
          required
        />

        <TextField
          fullWidth
          label="Wallet Description"
          value={metadata.wallet_description}
          onChange={handleTextChange('wallet_description')}
          margin="normal"
          multiline
          rows={3}
        />

        <FormControl fullWidth margin="normal" required>
          <InputLabel>Entity</InputLabel>
          <Select
            value={metadata.wallet_entity}
            onChange={handleEntityChange}
            label="Entity"
          >
            <MenuItem value="ALPHA NODE S.P.L">ALPHA NODE S.P.L</MenuItem>
            <MenuItem value="ALPHA NODE SP. Z O.O.">ALPHA NODE SP. Z O.O.</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Updating...' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditWalletDialog;

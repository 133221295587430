import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Switch,
  TablePagination,
  SelectChangeEvent,
  ClickAwayListener,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

interface AddressesSectionProps {
  socket: Socket | null;
}

interface WalletOption {
  wallet_id: string;
  wallet_name: string;
}

interface EditableDescriptionProps {
  addressId: string;
  initialDescription: string;
  onSave: (addressId: string, newDescription: string) => Promise<void>;
}

interface Address {
  id: string;
  wallet_id: string;
  address: string;
  asset: number;
  chain: number;
  classification: 'internal_system' | 'internal_user' | 'external' | 'external_exchange';
  description: string;
  blacklisted: boolean;
  created_at: string;
}

interface AddressResponse {
  addresses: Address[];
  total: number;
  page: number;
  rowsPerPage: number;
}

const EditableDescription: React.FC<EditableDescriptionProps> = ({
  addressId,
  initialDescription,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(initialDescription);
  const [error, setError] = useState<string | null>(null);

  const handleSave = async () => {
    try {
      await onSave(addressId, description);
      setIsEditing(false);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save description');
    }
  };

  if (isEditing) {
    return (
      <ClickAwayListener onClickAway={() => setIsEditing(false)}>
        <TextField
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSave();
            }
          }}
          error={!!error}
          helperText={error}
          size="small"
          autoFocus
          fullWidth
        />
      </ClickAwayListener>
    );
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Chip label={description} />
      <Tooltip title="Edit description">
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(true);
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const AddressesSection: React.FC<AddressesSectionProps> = ({ socket }) => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [totalAddresses, setTotalAddresses] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [wallets, setWallets] = useState<WalletOption[]>([]);
  const [chains, setChains] = useState<{ id: number; name: string; }[]>([]);

  // Filters
  const [walletFilter, setWalletFilter] = useState<string>('');
  const [chainFilter, setChainFilter] = useState<number | ''>('');
  const [classificationFilter, setClassificationFilter] = useState<string>('');
  const [blacklistedFilter, setBlacklistedFilter] = useState<boolean | ''>('');

  useEffect(() => {
    if (socket) {
      socket.emit('getTransactionServerConfig');
      socket.on('transactionServerConfig', (config: { chains: typeof chains }) => {
        setChains(config.chains);
      });

      socket.emit('getWallets');
      socket.on('walletsData', (data: { wallet_id: string; metadata: { wallet_name: string } }[]) => {
        setWallets(data.map(w => ({
          wallet_id: w.wallet_id,
          wallet_name: w.metadata.wallet_name
        })));
      });

      return () => {
        socket.off('transactionServerConfig');
        socket.off('walletsData');
      };
    }
  }, [socket]);

  const fetchAddresses = () => {
    if (socket) {
      socket.emit('getWalletAddresses', {
        page: page + 1,
        rowsPerPage,
        searchTerm,
        wallet_id: walletFilter,
        chain_id: chainFilter,
        classification: classificationFilter,
        blacklisted: blacklistedFilter
      });
    }
  };

  useEffect(() => {
    if (socket) {
      fetchAddresses();

      socket.on('walletAddressesData', (response: AddressResponse) => {
        setAddresses(response.addresses);
        setTotalAddresses(response.total);
      });

      return () => {
        socket.off('walletAddressesData');
      };
    }
  }, [socket, page, rowsPerPage, searchTerm, walletFilter, chainFilter, classificationFilter, blacklistedFilter]);

  const handleToggleBlacklist = async (addressId: string, currentBlacklistStatus: boolean) => {
    if (!socket) return;

    try {
      const response = await new Promise<{ status: string; message?: string }>((resolve) => {
        socket.emit('updateAddress', {
          address_id: addressId,
          blacklisted: !currentBlacklistStatus
        }, (res: any) => resolve(res));
      });

      if (response.status === 'error') {
        console.error(response.message);
        return;
      }

      fetchAddresses();
    } catch (err) {
      console.error('Failed to update blacklist status:', err);
    }
  };

  const handleUpdateDescription = async (addressId: string, newDescription: string) => {
    if (!socket) return;

    try {
      const response = await new Promise<{ status: string; message?: string }>((resolve) => {
        socket.emit('updateAddress', {
          address_id: addressId,
          description: newDescription,
        }, resolve);
      });

      if (response.status === 'error') {
        throw new Error(response.message);
      }

      fetchAddresses();
    } catch (err) {
      throw new Error(err instanceof Error ? err.message : 'Failed to update description');
    }
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Addresses
      </Typography>

      <Box display="flex" gap={2} mb={2}>
        <TextField
          label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by address or description"
          size="small"
        />

        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Wallet</InputLabel>
          <Select
            value={walletFilter}
            onChange={(e) => setWalletFilter(e.target.value)}
            label="Wallet"
          >
            <MenuItem value="">All</MenuItem>
            {wallets.map((wallet) => (
              <MenuItem key={wallet.wallet_id} value={wallet.wallet_id}>
                {wallet.wallet_name} ({wallet.wallet_id})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Chain</InputLabel>
          <Select
            value={chainFilter}
            onChange={(e: SelectChangeEvent<typeof chainFilter>) =>
              setChainFilter(e.target.value as typeof chainFilter)}
            label="Chain"
          >
            <MenuItem value="">All</MenuItem>
            {chains.map((chain) => (
              <MenuItem key={chain.id} value={chain.id}>{chain.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Classification</InputLabel>
          <Select
            value={classificationFilter}
            onChange={(e) => setClassificationFilter(e.target.value)}
            label="Classification"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="internal_system">Internal System</MenuItem>
            <MenuItem value="internal_user">Internal User</MenuItem>
            <MenuItem value="external">External</MenuItem>
            <MenuItem value="external_exchange">External Exchange</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Blacklisted</InputLabel>
          <Select
            value={blacklistedFilter.toString()}
            onChange={(e) => setBlacklistedFilter(e.target.value === '' ? '' : e.target.value === 'true')}
            label="Blacklisted"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Wallet ID</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Asset</TableCell>
              <TableCell>Chain</TableCell>
              <TableCell>Classification</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Blacklisted</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addresses.map((address) => (
              <TableRow key={address.id}>
                <TableCell>{address.wallet_id}</TableCell>
                <TableCell>
                  <Typography
                    sx={{
                      fontFamily: 'monospace',
                      wordBreak: 'break-all'
                    }}
                  >
                    {address.address}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Chip label={address.asset} />
                </TableCell>
                <TableCell>
                  <Chip label={chains.find(c => c.id === address.chain)?.name || address.chain} />
                </TableCell>
                <TableCell>
                  <Chip
                    label={address.classification.replace('_', ' ')}
                    color={
                      address.classification.startsWith('internal')
                        ? 'primary'
                        : 'default'
                    }
                  />
                </TableCell>
                <TableCell>
                  <EditableDescription
                    addressId={address.id}
                    initialDescription={address.description}
                    onSave={handleUpdateDescription}
                  />
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Switch
                      checked={address.blacklisted}
                      onChange={() => handleToggleBlacklist(address.id, address.blacklisted)}
                      color="error"
                    />
                    <Chip
                      label={address.blacklisted ? 'Yes' : 'No'}
                      color={address.blacklisted ? 'error' : 'success'}
                      size="small"
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  {new Date(address.created_at).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={totalAddresses}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
        rowsPerPageOptions={[5, 10, 25, 50]}
      />
    </Paper>
  );
};

export default AddressesSection;

import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { format } from 'date-fns';

interface TradingPairVolumeStatsProps {
  socket: Socket | null;
}

interface TradingPairVolumeData {
  pair: string;
  buyVolume: number;
  sellVolume: number;
}

const TradingPairVolumeStats: React.FC<TradingPairVolumeStatsProps> = ({ socket }) => {
  const [tradingPairVolumes, setTradingPairVolumes] = useState<TradingPairVolumeData[]>([]);

  useEffect(() => {
    if (socket) {
      socket.emit('requestTradingPairVolumes', format(new Date(), 'yyyy-MM-dd'));
      socket.on('tradingPairVolumeData', (data: TradingPairVolumeData[]) => setTradingPairVolumes(data));
    }

    return () => {
      if (socket) {
        socket.off('tradingPairVolumeData');
      }
    };
  }, [socket]);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>Trading Pair Volumes</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Trading Pair</TableCell>
              <TableCell>Buy Volume</TableCell>
              <TableCell>Sell Volume</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tradingPairVolumes.map((pair) => (
              <TableRow key={pair.pair}>
                <TableCell>{pair.pair}</TableCell>
                <TableCell>${pair.buyVolume.toLocaleString()}</TableCell>
                <TableCell>${pair.sellVolume.toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TradingPairVolumeStats;

import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { Paper, Typography, TextField, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface MonthlyVolumeStatsProps {
  socket: Socket | null;
}

interface VolumeData {
  date: string;
  totalVolume: number;
}

const MonthlyVolumeStats: React.FC<MonthlyVolumeStatsProps> = ({ socket }) => {
  const [selectedMonth, setSelectedMonth] = useState<Date | null>(new Date());
  const [monthlyVolume, setMonthlyVolume] = useState<VolumeData[]>([]);

  useEffect(() => {
    if (socket && selectedMonth) {
      const startDate = format(startOfMonth(selectedMonth), 'yyyy-MM-dd');
      const endDate = format(endOfMonth(selectedMonth), 'yyyy-MM-dd');
      socket.emit('requestMonthlyVolume', { startDate, endDate });
      socket.on('monthlyVolumeData', (data: VolumeData[]) => setMonthlyVolume(data));
    }

    return () => {
      if (socket) {
        socket.off('monthlyVolumeData');
      }
    };
  }, [socket, selectedMonth]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Monthly Volume</Typography>
        <Box sx={{ mb: 2 }}>
          <DatePicker
            label="Select Month"
            views={['year', 'month']}
            value={selectedMonth}
            onChange={(newValue) => setSelectedMonth(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
          Total Volume: ${monthlyVolume.reduce((acc, curr) => acc + curr.totalVolume, 0).toLocaleString()}
        </Typography>
        <Box sx={{ height: 300, mt: 2 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={monthlyVolume}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="totalVolume" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Paper>
    </LocalizationProvider>
  );
};

export default MonthlyVolumeStats;

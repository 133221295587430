import { format } from 'date-fns';

export const formatUnixTimestamp = (unixTimestamp: number): string => {
  // Convert milliseconds to seconds if necessary
  const timestamp = unixTimestamp > 1e12 ? unixTimestamp : unixTimestamp * 1000;

  // Create a Date object from the timestamp
  const date = new Date(timestamp);

  // Format the date to a readable string
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};

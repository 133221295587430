import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Container, Box, Typography, TextField, Button, ThemeProvider, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import TwoFASetup from './TwoFASetup';

interface LoginFormProps {
  socket: Socket | null;
  onLoginSuccess: () => void;
  theme: any;
}

const LoginForm: React.FC<LoginFormProps> = ({ socket, onLoginSuccess, theme }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [is2FARequired, setIs2FARequired] = useState(false);
  const [is2FASetupRequired, setIs2FASetupRequired] = useState(false);
  const [twoFACode, setTwoFACode] = useState('');
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    if (socket) {
      socket.emit('login', { username, password });
    }
  };

  const handle2FASubmit = () => {
    if (socket) {
      socket.emit('verify2FA', { username, twoFACode });
    }
  };

  const handlePasswordChange = () => {
    if (newPassword !== confirmNewPassword) {
      setError("Passwords don't match");
      return;
    }
    if (socket) {
      socket.emit('changePassword', { username, newPassword });
    }
  };

  const handle2FASetupComplete = () => {
    setIs2FASetupRequired(false);
    onLoginSuccess();
  };

  useEffect(() => {
    if (socket) {
      const handleLoginResponse = (response: { success: boolean, token?: string, requires2FA?: boolean, requires2FASetup?: boolean, isFirstLogin?: boolean }) => {
        if (response.success) {
          if (response.token) {
            localStorage.setItem('authToken', response.token);
          }
          if (response.requires2FA) {
            setIs2FARequired(true);
          } else if (response.requires2FASetup) {
            setIs2FASetupRequired(true);
          } else if (response.isFirstLogin) {
            setIsFirstLogin(true);
          } else {
            onLoginSuccess();
          }
        } else {
          setError('Invalid username or password');
        }
      };

      const handle2FAResponse = (response: { success: boolean, token?: string }) => {
        console.log("2fa response: " + JSON.stringify(response));
        if (response.success) {
          // localStorage.setItem('authToken', response.token);
          onLoginSuccess();
        } else {
          setError('Invalid 2FA code');
        }
      };

      const handlePasswordChangeResponse = (response: { success: boolean, token?: string, requires2FASetup?: boolean }) => {
        if (response.success) {
          if (response.token) {
            localStorage.setItem('authToken', response.token);
          }if (response.requires2FASetup) {
            setIs2FASetupRequired(true);
          } else {
            onLoginSuccess();
          }
        } else {
          setError('Failed to change password');
        }
      };

      socket.on('loginResponse', handleLoginResponse);
      socket.on('2FAResponse', handle2FAResponse);
      socket.on('passwordChangeResponse', handlePasswordChangeResponse);

      return () => {
        socket.off('loginResponse', handleLoginResponse);
        socket.off('2FAResponse', handle2FAResponse);
        socket.off('passwordChangeResponse', handlePasswordChangeResponse);
      };
    }
  }, [socket, onLoginSuccess]);

  if (is2FASetupRequired) {
    return <TwoFASetup socket={socket} username={username} onSetupComplete={handle2FASetupComplete} theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
              >
                <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                  {error}
                </Alert>
              </motion.div>
            )}
          </AnimatePresence>

          <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>

      <Dialog open={is2FARequired} onClose={() => setIs2FARequired(false)}>
        <DialogTitle>Enter 2FA Code</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="2fa-code"
            label="2FA Code"
            type="text"
            fullWidth
            variant="standard"
            value={twoFACode}
            onChange={(e) => setTwoFACode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIs2FARequired(false)}>Cancel</Button>
          <Button onClick={handle2FASubmit}>Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isFirstLogin} onClose={() => setIsFirstLogin(false)}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="new-password"
            label="New Password"
            type="password"
            fullWidth
            variant="standard"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            id="confirm-new-password"
            label="Confirm New Password"
            type="password"
            fullWidth
            variant="standard"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsFirstLogin(false)}>Cancel</Button>
          <Button onClick={handlePasswordChange}>Change Password</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default LoginForm;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField, Chip, Button, Select, MenuItem, FormControl, InputLabel, Dialog, DialogTitle,
  DialogContent, DialogActions, Checkbox, FormControlLabel, TablePagination, Alert
} from '@mui/material';
import { Socket } from 'socket.io-client';

interface User {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  organization: string;
  permissions: string[];
  active: boolean;
  is_staff: boolean;
}

interface Permission {
  id: number;
  permission: string;
}

interface UsersSectionProps {
  socket: Socket | null;
}

const UsersSection: React.FC<UsersSectionProps> = ({ socket }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [staffFilter, setStaffFilter] = useState<'all' | 'staff' | 'non-staff'>('all');
  const [openNewUserDialog, setOpenNewUserDialog] = useState(false);
  const [openNewPermissionDialog, setOpenNewPermissionDialog] = useState(false);
  const [newUser, setNewUser] = useState<Partial<User>>({
    email: '',
    first_name: '',
    last_name: '',
    organization: '',
    permissions: [],
    active: true,
    is_staff: true
  });
  const [newPermission, setNewPermission] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [responseMessage, setResponseMessage] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
      fetchUsers();
      fetchPermissions();

      socket.on('usersData', (data) => {
        setUsers(data.users);
        setTotalCount(data.total);
      });

      socket.on('permissionsData', (data) => {
        setPermissions(data.permissions);
      });
    }

    return () => {
      if (socket) {
        socket.off('usersData');
        socket.off('permissionsData');
      }
    };
  }, [socket, page, rowsPerPage, searchTerm, staffFilter]);

  const fetchUsers = () => {
    socket?.emit('requestUsers', {
      page: page + 1,
      rowsPerPage,
      searchTerm,
      staffFilter
    });
  };

  const fetchPermissions = () => {
    socket?.emit('requestPermissions');
  };

  const handleOrganizationClick = (organization: string) => {
    navigate(`/organization/${encodeURIComponent(organization)}`);
  };

  const handleUserClick = (userId: string) => {
    navigate(`/user/${userId}`);
  };

  const handleNewUserSubmit = () => {
    socket?.emit('addNewUser', newUser, (response: { success: boolean, user?: User, error?: string }) => {
      if (response.success && response.user) {
        setUsers([...users, response.user]);
        setResponseMessage({
          type: 'success',
          message: `User created successfully. Email: ${response.user.email}, Password: ${response.user.password}`
        });
        setNewUser({ email: '', first_name: '', last_name: '', organization: '', permissions: [], active: true, is_staff: true });
      } else {
        setResponseMessage({
          type: 'error',
          message: response.error || 'Failed to add new user'
        });
      }
    });
  };

  const handleNewPermissionSubmit = () => {
    socket?.emit('addNewPermission', { permission: newPermission },
      (response: { success: boolean, permission?: Permission, error?: string }) => {
      if (response.success && response.permission) {
        setPermissions([...permissions, response.permission]);
        setResponseMessage({
          type: 'success',
          message: `Permission "${response.permission.permission}" added successfully.`
        });
        setNewPermission('');
      } else {
        setResponseMessage({
          type: 'error',
          message: response.error || 'Failed to add new permission'
        });
      }
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialog = () => {
    setOpenNewUserDialog(false);
    setOpenNewPermissionDialog(false);
    setResponseMessage(null);
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Staff Users
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          label="Search users"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ flexGrow: 1 }}
        />
        <Button variant="contained" color="primary" onClick={() => setOpenNewUserDialog(true)}>
          Add New Staff User
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Permissions</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Staff</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.user_id}>
                <TableCell>{user.user_id}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Button onClick={() => handleOrganizationClick(user.organization)}>
                    {user.organization}
                  </Button>
                </TableCell>
                <TableCell>
                  {user.permissions.map(perm => (
                    <Chip key={perm} label={perm} size="small" sx={{ mr: 0.5 }} />
                  ))}
                </TableCell>
                <TableCell>
                  <Chip label={user.active ? 'Active' : 'Inactive'} color={user.active ? 'success' : 'error'} />
                </TableCell>
                <TableCell>
                  <Chip label={user.is_staff ? 'Staff' : 'Non-staff'} color={user.is_staff ? 'primary' : 'default'} />
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleUserClick(user.user_id)}>
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Permissions
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Button variant="contained" color="primary" onClick={() => setOpenNewPermissionDialog(true)}>
          Add New Permission
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Permission</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions.map((permission) => (
              <TableRow key={permission.id}>
                <TableCell>{permission.id}</TableCell>
                <TableCell>{permission.permission}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openNewUserDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add New Staff User</DialogTitle>
        <DialogContent>
          {responseMessage && (
            <Alert severity={responseMessage.type} sx={{ mt: 2, mb: 2 }}>
              {responseMessage.message}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <TextField
            margin="dense"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            value={newUser.first_name}
            onChange={(e) => setNewUser({ ...newUser, first_name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            value={newUser.last_name}
            onChange={(e) => setNewUser({ ...newUser, last_name: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleNewUserSubmit}>Add User</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNewPermissionDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add New Permission</DialogTitle>
        <DialogContent>
          {responseMessage && (
            <Alert severity={responseMessage.type} sx={{ mt: 2, mb: 2 }}>
              {responseMessage.message}
            </Alert>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Permission"
            type="text"
            fullWidth
            variant="standard"
            value={newPermission}
            onChange={(e) => setNewPermission(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleNewPermissionSubmit}>Add Permission</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
export default UsersSection;

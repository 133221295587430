import React, { useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { Paper, Typography, TextField, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

interface DailyVolumeStatsProps {
  socket: Socket | null;
}

interface VolumeData {
  date: string;
  totalVolume: number;
}

const DailyVolumeStats: React.FC<DailyVolumeStatsProps> = ({ socket }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [dailyVolume, setDailyVolume] = useState<VolumeData[]>([]);

  useEffect(() => {
    if (socket && selectedDate) {
      socket.emit('requestDailyVolume', format(selectedDate, 'yyyy-MM-dd'));
      socket.on('dailyVolumeData', (data: VolumeData[]) => setDailyVolume(data));
    }

    return () => {
      if (socket) {
        socket.off('dailyVolumeData');
      }
    };
  }, [socket, selectedDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Daily Volume</Typography>
        <Box sx={{ mb: 2 }}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Typography variant="h5" sx={{ mt: 2 }}>
          Total Volume: ${dailyVolume.length > 0 ? dailyVolume[0].totalVolume.toLocaleString() : '0'}
        </Typography>
      </Paper>
    </LocalizationProvider>
  );
};

export default DailyVolumeStats;
